import React from 'react';
import Icon from 'components/Icons';
import LinkUniversal from 'components/LinkUniversal';
import styles from './LinkInternational.module.scss';

interface Props {
    to: string;
    text: string;
    newTab?: boolean;
    showIcon?: boolean;
    tpLink?: false | 'internal' | 'external';
}

const LinkInternational = ({ to, text, newTab, showIcon, tpLink }: Props) => (
    <LinkUniversal
        to={to}
        className={styles.link}
        target={newTab ? '_blank' : '_self'}
        tpLink={tpLink}
    >
        <span>{text}</span> {showIcon && <Icon className={styles.linkIcon} name="external" />}
    </LinkUniversal>
);

export default LinkInternational;
