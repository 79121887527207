import React from 'react';

export const Sound = ({ className = '', viewBox = '0 0 27 26' }) => (
    <svg
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        className={`u-icon u-icon--sound ${className || ''}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <path d="M9.97834 1.94161L4.60442 6.45669H1.65981C0.850038 6.45669 0.1875 7.11922 0.1875 7.92899V17.2045C0.1875 18.0145 0.850038 18.6768 1.65981 18.6768H4.60442L9.97834 23.1919C10.9599 23.9281 12.334 23.241 12.334 22.0141V3.11946C12.334 1.89254 10.9353 1.20546 9.97834 1.94161Z" />
        <path d="M17.1916 4.34639C16.5291 3.68385 15.4248 3.68385 14.7625 4.34639C14.1 5.00893 14.1 6.11316 14.7625 6.7757C17.9525 9.96569 17.9525 15.1436 14.7625 18.3335C14.1 18.9961 14.1 20.1003 14.7625 20.7626C15.1058 21.1061 15.5475 21.2534 15.9647 21.2534C16.3818 21.2534 16.8483 21.0819 17.167 20.7381C21.7312 16.2475 21.7312 8.886 17.1916 4.34639Z" />
        <path d="M19.1061 0.493878C18.4438 1.15642 18.4438 2.26065 19.1061 2.92318C21.6826 5.49972 23.1061 8.91056 23.1061 12.5668C23.1061 16.223 21.6826 19.6339 19.1061 22.2104C18.4438 22.8729 18.4438 23.9772 19.1061 24.6397C19.4496 24.983 19.8913 25.155 20.3085 25.155C20.7502 25.155 21.1919 24.9832 21.5109 24.6642C24.7254 21.4497 26.5165 17.1555 26.5165 12.5913C26.5165 8.02718 24.7497 3.75749 21.5109 0.518416C20.8729 -0.16866 19.7932 -0.16866 19.1061 0.493878Z" />
    </svg>
);
