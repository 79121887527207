import React, { useState, useCallback, Dispatch, SetStateAction } from 'react';
import cx from 'classnames';
import Cookies from 'js-cookie';
import Modal from 'react-modal';
import { getViewportSize } from 'utils/getViewportSize';
import Icon from 'components/Icons';
import Section from 'components/Layout/Section';
import Container from 'components/Layout/Container';
import { AccordionGroup } from 'components/common/Accordion';
import UnderlineLink from 'components/common/UnderlineLink';
import { COOKIES } from 'components/CookiesGDPR';
import LinkUniversal from 'components/LinkUniversal';
import styles from './LanguagePicker.module.scss';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    title: string;
    intMsg?: string;
    intLink?: {
        label: string;
        url: string;
    };
    data?: Continents[];
    opened: boolean;
    setOpened: Dispatch<SetStateAction<boolean>>;
}

interface Continents {
    name: string;
    countries: Countries[];
}

interface Countries {
    name: string;
    languages: Languages[];
}

interface Languages {
    name: string;
    link: string;
}

export type LanguagePickerInterface = Continents;

Modal.setAppElement('body');

export const LanguagePicker = React.forwardRef<HTMLDivElement, Props>(
    ({ data, title, intMsg, intLink, opened, setOpened, ...rest }, ref) => {
        const [activeTab, setActiveTab] = useState(0);

        const handleClose = useCallback(() => {
            if (setOpened && typeof setOpened === 'function') setOpened(false);
        }, [setOpened]);

        const handleLangChange = useCallback(
            link => {
                if (link) {
                    Cookies.set(
                        COOKIES.ipLang.name,
                        link?.startsWith('/')
                            ? `${link.substring(1).toLowerCase()}`
                            : `${link.toLowerCase()}`,
                        {
                            expires: COOKIES.ipLang.expires,
                        }
                    );
                    handleClose();
                }
            },
            [handleClose]
        );

        return (
            <Modal
                ariaHideApp={false}
                isOpen={opened}
                closeTimeoutMS={300}
                className={styles.modalMain}
                overlayClassName={styles.modalOverlay}
                bodyOpenClassName={styles.isOpened}
            >
                <Section
                    ref={ref}
                    modifier=""
                    alignment=""
                    bgColor="white"
                    className={cx(styles.languagePicker)}
                    {...rest}
                >
                    <div className={styles.header}>
                        <span className={styles.logo}>
                            <img src="/logo.svg" alt="FitLine" />
                        </span>
                        <button
                            type="button"
                            onClick={() => handleClose()}
                            className={styles.close}
                        >
                            <Icon name={'close'} />
                        </button>
                    </div>
                    <Container>
                        <div className={styles.title}>
                            <h2 className={'u-a4 u-fw-700'}>{title}</h2>
                        </div>
                    </Container>
                    {getViewportSize() > 800 ? (
                        <div className={styles.tabs}>
                            <div className={styles.navWrapper}>
                                <Container>
                                    <ul className={cx(styles.nav, 'u-text-center')}>
                                        {data?.map((item, index) => (
                                            <li
                                                key={`languagePickerTab__${index}`}
                                                className={cx(
                                                    styles.navItem,
                                                    'u-a3 u-fw-700 u-uppercase'
                                                )}
                                            >
                                                <button
                                                    className={cx(styles.navButton, {
                                                        [styles.isActive]: activeTab === index,
                                                    })}
                                                    onClick={event => {
                                                        event.preventDefault();
                                                        setActiveTab(index);
                                                    }}
                                                >
                                                    {item.name}
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </Container>
                            </div>
                            <Container>
                                {data?.map((items, index) => (
                                    <ul
                                        key={`languagePickerContinent__${index}`}
                                        className={cx(styles.list, {
                                            [styles.isActive]: activeTab === index,
                                        })}
                                    >
                                        {items?.countries?.map((country, index) => (
                                            <li
                                                key={`languagePickerCountry__${index}`}
                                                className={cx(styles.listItem)}
                                            >
                                                <h5 className="u-a2 u-fw-400">{country.name}</h5>
                                                <ul className={cx(styles.languages, 'u-b0')}>
                                                    {country?.languages?.map((language, index) => (
                                                        <li
                                                            key={`languagePickerLanguage__${index}`}
                                                        >
                                                            <LinkUniversal
                                                                onClick={() =>
                                                                    handleLangChange(language?.link)
                                                                }
                                                                to={language?.link || '#'}
                                                                tpLink="internal"
                                                            >
                                                                {language.name}
                                                            </LinkUniversal>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </li>
                                        ))}
                                    </ul>
                                ))}
                            </Container>
                        </div>
                    ) : (
                        <div className={styles.accordion}>
                            {data?.map((items, index) => (
                                <AccordionGroup
                                    isMono={false}
                                    key={`country__${items.name}__${index}`}
                                    headerFont="u-a3 u-fw-700 u-uppercase"
                                    className={styles.accordionSingle}
                                    color="white"
                                    content={[
                                        {
                                            title: items.name,
                                            description: (
                                                <ul>
                                                    {items?.countries?.map((country, index) => (
                                                        <li
                                                            key={index}
                                                            className={cx(styles.listItem)}
                                                        >
                                                            <h5 className={'u-a2 u-fw-400'}>
                                                                {country.name}
                                                            </h5>
                                                            <ul
                                                                className={cx(
                                                                    styles.languages,
                                                                    'u-b0'
                                                                )}
                                                            >
                                                                {country?.languages?.map(
                                                                    (language, index) => (
                                                                        <li key={index}>
                                                                            <LinkUniversal
                                                                                tpLink="internal"
                                                                                to={
                                                                                    language?.link ||
                                                                                    '#'
                                                                                }
                                                                                onClick={() =>
                                                                                    handleLangChange(
                                                                                        language?.link
                                                                                    )
                                                                                }
                                                                            >
                                                                                {language.name}
                                                                            </LinkUniversal>
                                                                        </li>
                                                                    )
                                                                )}
                                                            </ul>
                                                        </li>
                                                    ))}
                                                </ul>
                                            ),
                                        },
                                    ]}
                                />
                            ))}
                        </div>
                    )}
                    {intMsg && intLink && (
                        <Container>
                            <div className={styles.cta}>
                                <h4 className={cx(styles.ctaTitle, 'u-a3 u-fw-700')}>{intMsg}</h4>
                                <UnderlineLink
                                    to={intLink.url}
                                    tpLink="internal"
                                    className="u-b1 u-fw-500"
                                    onClick={() => handleLangChange('en')}
                                >
                                    {intLink.label}
                                </UnderlineLink>
                            </div>
                        </Container>
                    )}
                </Section>
            </Modal>
        );
    }
);
