import countries from 'scripts/countries';

const slugCheck = query => {
    const slug = query?.slug?.toLowerCase() || '/';
    const country = query?.country?.toLowerCase() || 'int';

    if (countries.includes(query?.country) || query?.country === 'int') {
        return {
            ...query,
            country,
            slug,
        };
    }

    return {
        ...query,
        country: 'int',
        slug: query?.country,
    };
};

export const isInCountries = country => countries.includes(country);

export default slugCheck;
