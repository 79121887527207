import React from 'react';

export const ArrowRight = ({ className = '', viewBox = '0 0 6 9' }) => (
    <svg
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        className={`u-icon u-icon--arrow-right ${className || ''}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <path d="M1.414.292L0 1.706 2.793 4.5 0 7.292l1.414 1.414L5.621 4.5 1.414.292z" />
    </svg>
);
