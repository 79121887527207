import React from 'react';

export const Facebook = ({ className = '', viewBox = '0 0 16 16' }) => (
    <svg
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        className={`u-icon u-icon--close ${className || ''}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <path
            d="M10.5,2.7H12V0.1C11.7,0.1,10.9,0,9.9,0C7.8,0,6.3,1.3,6.3,3.8V6H4v2.8h2.3V16h2.8V8.8h2.2L11.8,6H9.2V4
	C9.2,3.2,9.4,2.7,10.5,2.7z"
        />
    </svg>
);
