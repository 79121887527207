import React, { useEffect, useCallback, useRef } from 'react';
import { gsap } from 'gsap';
import cx from 'classnames';
import { useAppContext } from 'context';
import styles from './PageLoader.module.scss';

const PageLoader = () => {
    const { loading } = useAppContext();
    const $element = useRef(null);

    const fadeIn = useCallback(() => {
        if ($element?.current) {
            gsap.to($element?.current, {
                duration: 0.2,
                autoAlpha: 1,
            });
        }
    }, [$element]);

    const fadeOut = useCallback(() => {
        if ($element?.current) {
            gsap.to($element?.current, {
                delay: 0.4,
                duration: 0.2,
                autoAlpha: 0,
            });
        }
    }, [$element]);

    useEffect(() => {
        loading ? fadeIn() : fadeOut();
    }, [loading, fadeIn, fadeOut]);

    return (
        <span
            ref={$element}
            className={cx(styles.transition, styles[`transition--${loading ? 'in' : 'out'}`])}
        >
            <div className={styles.inner}>
                <svg
                    width="160"
                    height="160"
                    viewBox="0 0 160 160"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M80 150C118.66 150 150 118.66 150 80C150 41.3401 118.66 10 80 10C41.3401 10 10 41.3401 10 80C10 118.66 41.3401 150 80 150ZM80 160C124.183 160 160 124.183 160 80C160 35.8172 124.183 0 80 0C35.8172 0 0 35.8172 0 80C0 124.183 35.8172 160 80 160Z"
                        fill="white"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M75 5C75 2.23858 77.2386 0 80 0C124.183 0 160 35.8172 160 80C160 82.7614 157.761 85 155 85C152.239 85 150 82.7614 150 80C150 41.3401 118.66 10 80 10C77.2386 10 75 7.76142 75 5Z"
                        fill="#CD0039"
                    />
                </svg>
            </div>
        </span>
    );
};

export default PageLoader;
