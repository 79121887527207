import React from 'react';

export const Instagram = ({ className = '', viewBox = '0 0 18 18' }) => (
    <svg
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        className={`u-icon u-icon--instagram ${className || ''}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <path d="M9.003 4.378a4.618 4.618 0 100 9.236 4.618 4.618 0 000-9.236zm0 7.616a2.997 2.997 0 110-5.995 2.997 2.997 0 110 5.995z" />
        <path d="M12.711.057c-1.656-.078-5.758-.074-7.415 0-1.457.068-2.741.42-3.777 1.455C-.212 3.243.009 5.576.009 8.997c0 3.5-.195 5.779 1.51 7.484 1.738 1.737 4.104 1.51 7.484 1.51 3.468 0 4.665.002 5.891-.473 1.668-.647 2.926-2.138 3.05-4.814.077-1.657.073-5.758 0-7.415-.15-3.16-1.845-5.076-5.233-5.232zm2.621 15.278c-1.134 1.135-2.709 1.034-6.35 1.034-3.75 0-5.254.055-6.352-1.045-1.263-1.258-1.035-3.277-1.035-6.34 0-4.143-.425-7.127 3.734-7.34.955-.034 1.237-.045 3.642-.045l.034.022c3.996 0 7.132-.418 7.32 3.74.043.948.053 1.233.053 3.635 0 3.706.07 5.219-1.046 6.34z" />
        <path d="M13.804 5.275a1.08 1.08 0 100-2.158 1.08 1.08 0 000 2.158z" />
    </svg>
);
