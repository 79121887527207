import React from 'react';

export const Pinterest = ({ className = '', viewBox = '0 0 16 16' }) => (
    <svg
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        className={`u-icon u-icon--close ${className || ''}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <path
            d="M8.4,0C4,0,1.7,2.8,1.7,5.9c0,1.4,0.8,3.2,2.1,3.8c0.4,0.2,0.3,0,0.6-1.2c0-0.1,0-0.2-0.1-0.3C2.5,6,4,1.7,8.1,1.7
	c6.1,0,4.9,8.4,1.1,8.4c-1,0-1.7-0.8-1.5-1.8C8,7.2,8.5,5.9,8.5,5.1c0-2.1-3.1-1.8-3.1,1c0,0.9,0.3,1.4,0.3,1.4s-1,4.1-1.2,4.8
	c-0.3,1.3,0,3.4,0.1,3.5c0,0.1,0.1,0.1,0.2,0c0.1-0.1,1.3-1.9,1.7-3.1c0.1-0.5,0.6-2.3,0.6-2.3c0.3,0.6,1.3,1.1,2.3,1.1
	c3.1,0,5.3-2.7,5.3-6C14.7,2.4,11.9,0,8.4,0L8.4,0z"
        />
    </svg>
);
