import React from 'react';

export const ArrowLeft = ({ className = '', viewBox = '0 0 6 9' }) => (
    <svg
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        className={`u-icon u-icon--arrow-left ${className || ''}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <path d="M4.207.293l1.414 1.414L2.828 4.5l2.793 2.793-1.414 1.414L0 4.5 4.207.293z" />
    </svg>
);
