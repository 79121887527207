import React from 'react';

export const VideoPlay = ({ className = '', viewBox = '0 0 105 105' }) => (
    <svg
        width="105"
        height="105"
        viewBox="0 0 105 105"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`u-icon--videoplay ${className || ''}`}
    >
        <circle cx="52.5" cy="52.5" r="50.2174" stroke="#CD0039" strokeWidth="4.56522" />
        <path
            d="M65.0724 53.0976L44.8693 71.7467C44.0962 72.4603 42.8438 71.912 42.8438 70.8598V34.0798C42.8438 33.0357 44.0797 32.4842 44.8567 33.1815L65.0599 51.3126C65.5893 51.7876 65.5951 52.6152 65.0724 53.0976Z"
            fill="white"
        />
    </svg>
);
