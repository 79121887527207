import React, { useEffect, useState, useMemo, useCallback } from 'react';
import qs from 'query-string';
import PropTypes from 'prop-types';
import Router from 'next/router';
import AppWrapper from 'context/App';
import Navigation, {
    NavigationSideMenu,
    NavigationSideMenuItem,
    NavigationDropdown,
} from 'components/Layout/Navigation';
import { clarity } from 'react-microsoft-clarity';
import { AppProps as NextAppProps } from 'next/app';
import Button from 'components/common/Button';
import LinkInternational from 'components/common/LinkInternational';
import LinkUniversal from 'components/LinkUniversal';
import Footer from 'components/Layout/Footer';
import LanguagePicker from 'components/LanguagePicker';
import { tpLinkType } from 'utils/generateTpLink';
import { isInCountries } from 'utils/slugCheck';
import IPLanguagePicker from 'components/IPLanguagePicker';
import { generateCountryCode } from 'utils/getPMIcountryCodes';
import SEO from 'components/Seo';
import PageLoader from 'components/Layout/PageLoader';
import CookiesGDPR from 'components/CookiesGDPR';
import { initializeTrackings, sendPageView } from 'components/CookiesGDPR';
import 'components/Layout/Container/Container.scss';
import 'components/Layout/Section/Section.scss';
import 'styles/styles-site.scss';
import NextScript from 'next/script';

type AppProps<P = any> = {
    pageProps: P;
} & Omit<NextAppProps<P>, 'pageProps'>;

const MyApp = ({ Component, pageProps, router }: AppProps) => {
    const [isLoading, setLoading] = useState(false);
    const [langModal, setLangModal] = useState(false);
    const [languageBarHeight, setLanguageBarHeight] = useState(0);
    const [cookiesModal, setCookieModal] = useState(null);

    useEffect(() => {
        initializeTrackings(router?.asPath);
    }, [router]);

    useEffect(() => {
        Router.events.on('routeChangeStart', (url, { shallow }) => {
            if (!shallow) setLoading(true);
        });

        Router.events.on('routeChangeComplete', (url, { shallow }) => {
            if (!shallow) setLoading(false);
            sendPageView(url);
        });
    }, []);

    const homePageURL = useMemo(() => {
        if (
            router?.query?.language &&
            router?.query?.country !== 'int' &&
            isInCountries(router?.query?.country)
        ) {
            return `/${router?.query?.language}/${router?.query?.country}`;
        } else if (router?.query?.language) {
            return `/${router?.query?.language}`;
        }
    }, [router.query]);

    const navigation = useMemo(
        () => [...(pageProps?.layout?.navigation?.items || [])],
        [pageProps]
    );

    const sideNav = useMemo(() => ({ ...pageProps?.layout?.navigation?.sideNav }), [pageProps]);

    const footer = useMemo(
        () => ({
            logos: { ...pageProps?.layout?.footer?.logos },
            navigation: [...(pageProps?.layout?.footer?.navigation || [])],
            copyright: pageProps?.layout?.footer?.copyright,
            bottomNavigation: [...(pageProps?.layout?.footer?.bottomNavigation || [])],
        }),
        [pageProps]
    );

    const translations = useMemo(() => ({ ...pageProps?.layout?.translations }), [pageProps]);

    const languagePicker = useMemo(() => pageProps?.layout?.languagePicker || {}, [pageProps]);

    const locales = useMemo(
        () => ({
            language: `${router?.query?.language}`?.toLowerCase(),
            countryCode: generateCountryCode(router?.query?.country?.toString()?.toLowerCase()),
        }),
        [router?.query]
    );

    useEffect(() => {
        if (typeof document !== 'undefined') {
            document.documentElement.lang = locales?.language ?? 'en';
        }
    }, [locales]);

    const handleLanguagePicker = useCallback(() => setLangModal(!langModal), [langModal]);

    const pmiInternationalURL = useMemo(() => {
        if (sideNav?.pmInternational?.url && locales?.language && locales?.countryCode) {
            const queryParams =
                locales?.countryCode === 'int'
                    ? { lang: locales?.language }
                    : { lang: locales?.language, country: locales?.countryCode };
            return qs.stringifyUrl({
                url: sideNav.pmInternational.url,
                query: {
                    ...queryParams,
                },
            });
        } else {
            return null;
        }
    }, [sideNav, locales]);

    useEffect(() => {
        if (process.env.NEXT_PUBLIC_CLARITY_ID) clarity.init(process.env.NEXT_PUBLIC_CLARITY_ID);
    }, []);

    useEffect(() => {
        const credits = [
            'background-color: #000000',
            'color: white',
            'display: block',
            'line-height: 24px',
            'text-align: center',
            'border: 1px solid #ffffff',
            'font-weight: bold',
        ].join(';');
        console.info('dev by: %c Bornfight ', credits);
    }, []);

    return (
        <AppWrapper
            loading={isLoading}
            translations={translations}
            language={locales.language}
            countryCode={locales.countryCode}
            cookies={{
                opened: cookiesModal,
                setOpened: setCookieModal,
            }}
        >
            <SEO
                title={pageProps?.title}
                data={pageProps?.pageData?.seo}
                language={locales.language}
                hreflangs={pageProps?.pageData?.hreflangs || []}
            />
            <IPLanguagePicker
                onResize={height => setLanguageBarHeight(height)}
                onSelectClick={() => handleLanguagePicker()}
                onClose={() => setLanguageBarHeight(0)}
            />
            {process.env.NEXT_PUBLIC_HUBSPOT_SCRIPT_LOADER_URL && (
                <script
                    type="text/javascript"
                    id="hs-script-loader"
                    async
                    defer
                    src={process.env.NEXT_PUBLIC_HUBSPOT_SCRIPT_LOADER_URL}
                />
            )}
            <Navigation home={homePageURL} navigation={navigation} offsetTop={languageBarHeight}>
                <NavigationSideMenu>
                    {sideNav?.joinUs && sideNav?.joinUs?.showInNavigation && (
                        <NavigationSideMenuItem inherit={false}>
                            <Button
                                to={sideNav?.joinUs?.url}
                                buttonWidth="xsmall"
                                buttonHeight="xsmall"
                                target={sideNav?.joinUs?.newTab ? '_blank' : '_self'}
                                tpLink={tpLinkType(sideNav?.joinUs?.addTp, sideNav?.joinUs?.newTab)}
                            >
                                {sideNav?.joinUs?.label}
                            </Button>
                        </NavigationSideMenuItem>
                    )}
                    {sideNav?.pmInternational && sideNav?.pmInternational?.showInNavigation && (
                        <NavigationSideMenuItem>
                            <LinkInternational
                                newTab
                                showIcon
                                text={sideNav?.pmInternational?.label}
                                to={pmiInternationalURL || sideNav?.pmInternational?.url}
                                tpLink={tpLinkType(
                                    sideNav?.pmInternational?.addTp,
                                    sideNav?.pmInternational?.newTab
                                )}
                            />
                        </NavigationSideMenuItem>
                    )}
                    {languagePicker?.regions?.length > 0 && (
                        <NavigationSideMenuItem>
                            <NavigationDropdown
                                labels={[
                                    locales.countryCode.toUpperCase(),
                                    locales.language.toUpperCase(),
                                ]}
                                items={[
                                    ...pageProps?.layout?.availableLanguages?.map(langItem => ({
                                        title: langItem.name,
                                        url: langItem.link,
                                    })),
                                    {
                                        title: pageProps?.layout?.languagePicker?.cta,
                                        onClick: () => setLangModal(true),
                                    },
                                ]}
                            />
                        </NavigationSideMenuItem>
                    )}
                    {sideNav?.login && sideNav?.login?.url && sideNav?.login?.showInNavigation && (
                        <NavigationSideMenuItem>
                            <LinkUniversal
                                to={sideNav?.login?.url}
                                target={sideNav?.login?.newTab ? '_blank' : '_self'}
                                tpLink={tpLinkType(sideNav?.login?.addTp, sideNav?.login?.newTab)}
                            >
                                {sideNav?.login?.label}
                            </LinkUniversal>
                        </NavigationSideMenuItem>
                    )}
                </NavigationSideMenu>
            </Navigation>
            <main className="o-page">
                <Component {...pageProps} />
            </main>
            {languagePicker?.regions?.length > 0 && (
                <LanguagePicker
                    opened={langModal}
                    setOpened={setLangModal}
                    data={languagePicker?.regions}
                    title={languagePicker?.title}
                    intMsg={languagePicker?.international?.message}
                    intLink={{
                        label: languagePicker?.international?.linkLabel,
                        url: languagePicker?.international?.link,
                    }}
                />
            )}
            <Footer
                home={homePageURL}
                logos={footer?.logos}
                navigation={footer?.navigation}
                copyright={footer?.copyright}
                bottomNavigation={footer?.bottomNavigation}
            />
            <CookiesGDPR
                cookiesData={pageProps?.layout?.cookies}
                settingsModal={{
                    opened: cookiesModal,
                    setOpened: setCookieModal,
                }}
            />
            <PageLoader />
            <NextScript
                id="hsforms"
                src="//js-eu1.hsforms.net/forms/embed/v2.js"
                strategy="beforeInteractive"
            />
            {process.env.NEXT_PUBLIC_WEBCHAT_CLIENT_ID && (
                <NextScript
                    id="webchat"
                    src="//cdn.botpress.cloud/webchat/v0/inject.js"
                    onLoad={() => {
                        // @ts-ignore
                        window.botpressWebChat.init({
                            composerPlaceholder: 'Chat with Fitline',
                            botConversationDescription: 'Welcome to Fitline',
                            botId: '03473f9a-657a-4171-b26b-253b0a31a327',
                            hostUrl: 'https://cdn.botpress.cloud/webchat/v0',
                            messagingUrl: 'https://messaging.botpress.cloud',
                            clientId: process.env.NEXT_PUBLIC_WEBCHAT_CLIENT_ID,
                            lazySocket: true,
                            botName: 'Fitline',
                            avatarUrl: 'https://www.fitline.com/logo.svg',
                            stylesheet:
                                'https://cdn.jsdelivr.net/gh/almeidalucasmkt/testecsss@main/stylefitlinefinal2.css',
                            frontendVersion: 'v0',
                            useSessionStorage: true,
                            theme: 'prism',
                            themeColor: '#2563eb',
                        });
                    }}
                />
            )}
        </AppWrapper>
    );
};

MyApp.propTypes = {
    Component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    pageProps: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};

export default MyApp;

export function reportWebVitals(metric) {
    const styling = ['color: gold', 'display: block'].join(';');
    if (process.env.NODE_ENV === 'development') {
        if (metric?.name === 'TTFB') {
            const timeSec = parseFloat(`${Number(metric?.value) / 1000}`).toFixed(2);
            console.info(`%cWeb vitals (TTFB): ${timeSec}s`, styling);
        }
        if (metric.name === 'Next.js-route-change-to-render') {
            const timeSec = parseFloat(`${Number(metric?.value) / 1000}`).toFixed(2);
            console.info(`%cWeb vitals (change-to-render): ${timeSec}s`, styling);
        }
    }
}
