import React from 'react';

export const Clipboard = ({ className = '', viewBox = '0 0 24 24' }) => (
    <svg
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        className={`u-icon u-icon--clipboard ${className || ''}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <path d="M5.99915 0H21.4277C22.8479 0 23.9991 1.15126 23.9991 2.57143V18C23.9991 19.4202 22.8479 20.5715 21.4277 20.5715C20.9543 20.5715 20.5706 20.1877 20.5706 19.7143C20.5706 19.2409 20.9543 18.8571 21.4277 18.8571C21.9011 18.8571 22.2849 18.4734 22.2849 18V2.57143C22.2849 2.09802 21.9011 1.71427 21.4277 1.71427H5.99915C5.52576 1.71427 5.14203 2.09802 5.14203 2.57143C5.14203 3.04483 4.7583 3.42859 4.28491 3.42859C3.81146 3.42859 3.42773 3.04483 3.42773 2.57143C3.42773 1.15126 4.57898 0 5.99915 0ZM2.57141 4.28516H17.1428C18.563 4.28516 19.7142 5.43642 19.7142 6.85658V21.428C19.7142 22.8481 18.563 23.9994 17.1428 23.9994H2.57141C1.15125 23.9994 0 22.8481 0 21.428V6.85658C0 5.43642 1.15125 4.28516 2.57141 4.28516ZM17.1428 22.2852C17.6162 22.2852 18 21.9014 18 21.428V6.85658C18 6.38318 17.6162 5.99942 17.1428 5.99942H2.57141C2.09802 5.99942 1.71429 6.38318 1.71429 6.85658V21.428C1.71429 21.9014 2.09802 22.2852 2.57141 22.2852H17.1428Z" />
    </svg>
);
