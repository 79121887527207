import qs from 'query-string';
import getPMIcountryCodes from 'utils/getPMIcountryCodes';

const generateTPlink = ({ url = null, tp = null, countryCode = null, language = null }) => {
    let value = url || '';

    if (url && tp) {
        value = qs.stringifyUrl({
            url: url,
            query: { TP: tp, ...getPMIcountryCodes(countryCode, language) },
        });
    }

    return value;
};

const tpLinkType = (add, newTab) => {
    if (add && newTab) {
        return 'external';
    } else if (add && !newTab) {
        return 'internal';
    } else {
        return false;
    }
};

export { tpLinkType };

export default generateTPlink;
