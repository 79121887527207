import React, { useCallback, useMemo } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import generateTPlink from 'utils/generateTpLink';
import { useAppContext } from '@root/context';

interface Props extends React.StyleHTMLAttributes<any> {
    to: string;
    target?: string;
    tpLink?: false | 'internal' | 'external';
    prefetch?: boolean;
}

const LinkUniversal = React.forwardRef<HTMLAnchorElement, Props>(
    ({ children, to, target, tpLink, prefetch = false, ...other }, ref) => {
        const router = useRouter();
        const { language, countryCode } = useAppContext();

        const generatedURL = useMemo(() => {
            if (tpLink === 'internal') {
                return generateTPlink({ url: to, tp: router?.query?.TP });
            } else if (tpLink === 'external') {
                return generateTPlink({ url: to, tp: router?.query?.TP, language, countryCode });
            } else {
                return to || null;
            }
        }, [router, language, countryCode, to, tpLink]);

        const handleClick = useCallback(
            ev => {
                ev?.preventDefault();
                if (other?.onClick && typeof other?.onClick === 'function') other.onClick(ev);
                if (ev.currentTarget.href) {
                    if (ev.currentTarget.getAttribute('target') === '_blank') {
                        window.open(ev.currentTarget.href, '_blank');
                    } else {
                        router.push(ev.currentTarget.href);
                    }
                }
            },
            [other, router]
        );

        return prefetch ? (
            <Link href={generatedURL} prefetch={prefetch}>
                <a ref={ref} target={target || '_self'} {...other}>
                    {children}
                </a>
            </Link>
        ) : (
            <a
                ref={ref}
                href={generatedURL}
                target={target || '_self'}
                {...other}
                onClick={handleClick}
            >
                {children}
            </a>
        );
    }
);

export default LinkUniversal;
