import React from 'react';

export const Heart = ({ className = '', viewBox = '0 0 1024 1024' }) => (
    <svg
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        className={`u-icon u-icon--heart ${className || ''}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <path d="M942.763 168.843c-53.009-53.851-125.739-83.51-204.817-83.51-59.109 0-113.243 17.504-160.898 52.022-24.047 17.423-45.836 38.74-65.047 63.621-19.203-24.873-41-46.197-65.055-63.621-47.648-34.518-101.782-52.022-160.891-52.022-79.078 0-151.82 29.658-204.828 83.51-52.375 53.222-81.227 125.931-81.227 204.742 0 81.117 32.273 155.37 101.563 233.684 61.983 70.052 151.070 141.166 254.234 223.512 35.228 28.123 75.157 59.998 116.618 93.952 10.953 8.987 25.008 13.933 39.586 13.933 14.571 0 28.633-4.947 39.57-13.918 41.461-33.961 81.415-65.852 116.657-93.988 103.148-82.332 192.233-153.438 254.219-223.498 69.291-78.307 101.555-152.56 101.555-233.684 0-78.804-28.851-151.513-81.238-204.735z" />
    </svg>
);
