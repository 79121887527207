import React from 'react';
import cx from 'classnames';
import ParallaxElement from 'components/Layout/ParallaxElement';
import styles from './ParallaxOvalImage.module.scss';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    parallaxBackground?: string;
    lazy?: boolean;
    parallax?: boolean;
    hasBorderRadius?: boolean;
}

export const ParallaxOvalImage = React.forwardRef<HTMLDivElement, Props>(
    (
        {
            parallaxBackground,
            lazy = true,
            parallax = true,
            hasBorderRadius = false,
            className,
            ...rest
        },
        ref
    ) => (
        <div
            ref={ref}
            className={cx(
                styles.parallaxOvalImage,
                { [styles.hasBorderRadius]: hasBorderRadius },
                className
            )}
            {...rest}
        >
            <div
                className={cx(styles.shape, {
                    [styles.parallaxDisabled]: parallax === false,
                })}
            >
                {parallax ? (
                    <>
                        {lazy ? (
                            <ParallaxElement
                                percentage={10}
                                className={cx(styles.parallaxBackground, 'u-lazy js-lazy')}
                                data-bg={parallaxBackground}
                            />
                        ) : (
                            <ParallaxElement
                                percentage={10}
                                className={cx(styles.parallaxBackground)}
                                style={{
                                    backgroundImage: `url(${parallaxBackground})`,
                                }}
                            />
                        )}
                    </>
                ) : (
                    <>
                        {lazy ? (
                            <div
                                className={cx(styles.parallaxBackground, 'u-lazy js-lazy')}
                                data-bg={parallaxBackground}
                            />
                        ) : (
                            <div
                                className={cx(styles.parallaxBackground)}
                                style={{
                                    backgroundImage: `url(${parallaxBackground})`,
                                }}
                            />
                        )}
                    </>
                )}
            </div>
        </div>
    )
);
