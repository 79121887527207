import React from 'react';

export const VideoIndicator = ({ className = '', viewBox = '0 0 23 23' }) => (
    <svg
        viewBox="0 0 23 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`u-icon--videoindicator ${className || ''}`}
    >
        <circle cx="11.5" cy="11.5" r="11" stroke="#CD0039" />
        <path
            d="M14.2539 11.6313L9.82845 15.7164C9.6591 15.8727 9.38477 15.7526 9.38477 15.5221V7.46552C9.38477 7.23681 9.6555 7.11601 9.82571 7.26876L14.2512 11.2403C14.3671 11.3444 14.3684 11.5257 14.2539 11.6313Z"
            fill="#A62450"
        />
    </svg>
);
