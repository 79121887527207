import React from 'react';

export const Quote = ({ className = '', viewBox = '0 0 27 21' }) => (
    <svg
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        className={`u-icon u-icon--quote ${className || ''}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <path d="M8.728.768C3.04 4.512.232 9.336.232 14.52c0 4.104 2.592 6.48 5.616 6.48 3.24 0 5.328-2.376 5.328-5.688 0-3.024-1.8-5.112-4.104-5.112-.72 0-.864-.504-.72-1.224.36-2.16 1.368-3.744 4.032-6.336C11.464 1.56 9.952.048 8.728.768zm15.192 0c-5.688 3.744-8.496 8.568-8.496 13.752 0 4.104 2.592 6.48 5.616 6.48 3.24 0 5.328-2.376 5.328-5.688 0-3.024-1.8-5.112-4.104-5.112-.72 0-.864-.504-.72-1.224.36-2.16 1.368-3.744 4.032-6.336 1.08-1.08-.432-2.592-1.656-1.872z" />
    </svg>
);
