import React, { useEffect, useRef, useState, useCallback } from 'react';
import cx from 'classnames';
import { getViewportSize } from 'utils/getViewportSize';
import { BREAKPOINT_LG } from 'utils/breakpoints';
import Icon from 'components/Icons';
import Athlete from 'components/common/Athlete';
import ArticleItem, { generateCategories } from 'components/common/ArticleItem';
import LinkUniversal from 'components/LinkUniversal';
import styles from './Navigation.module.scss';

export type submenuTypes = 'none' | 'products' | 'productsExternal' | 'sports' | 'magazines';

type Link = {
    label: string;
    url: string;
    target: string;
    linkType?: string;
    addTp?: boolean;
};

interface NavigationProps extends Link {
    submenu?: Submenu;
}

type NavigationWithSubmenu = {
    submenu?: Submenu;
};

export type NavigationItems = Link & NavigationWithSubmenu;

interface Submenu {
    isActive: boolean;
    top: Link[];
    bottom: Link[];
    type: submenuTypes;
    content?: any;
    mobileBtnBackOnClick?: (ev: any) => void;
    onClick?: () => void;
}

interface Props extends React.StyleHTMLAttributes<any> {
    home?: string;
    logo?: string;
    siteTitle?: string;
    navigation?: NavigationProps[];
    mobileNavRight?: any;
    offsetTop?: number;
}

export const Navigation = ({
    children,
    home,
    logo,
    siteTitle,
    navigation,
    mobileNavRight,
    offsetTop,
}: Props) => {
    const timer = useRef(null);
    const [dropdownStates, setDropdownStates] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [mobileMenu, setMobileMenu] = useState(false);
    const [submenuMobileActive, setSubmenuMobileActive] = useState(false);
    const navRef = useRef(null);

    const defaultLogo = '/logo.svg';

    useEffect(() => {
        if (navigation?.length > 0) {
            const states = navigation?.map(() => false);
            setDropdownStates(states);
        }
    }, [navigation]);

    const mouseDropDownOpen = useCallback(
        index => {
            if (getViewportSize() > BREAKPOINT_LG) {
                clearTimeout(timer.current);
                const states = navigation.map(() => false);
                states[index] = true;
                setDropdownStates(states);
                setDropdownOpen(!!navigation[index].submenu);
            }
        },
        [timer, navigation]
    );

    const mouseDropDownClose = useCallback(() => {
        if (getViewportSize() > BREAKPOINT_LG) {
            timer.current = setTimeout(() => {
                const states = navigation.map(() => false);
                setDropdownStates(states);
                setDropdownOpen(false);
            }, 500);
        }
    }, [navigation, timer]);

    const mobileDropDownOpen = useCallback(
        (ev, index) => {
            ev.preventDefault();
            if (getViewportSize() <= BREAKPOINT_LG) {
                const states = navigation.map(() => false);
                states[index] = true;
                setDropdownStates(states);
                setTimeout(() => {
                    setSubmenuMobileActive(true);
                }, 10);
            }
        },
        [navigation]
    );

    const mobileDropDownClose = useCallback(
        ev => {
            ev.preventDefault();
            setSubmenuMobileActive(false);
            setTimeout(() => {
                const states = navigation.map(() => false);
                setDropdownStates(states);
            }, 300);
        },
        [navigation]
    );

    const closeMobileMenu = useCallback(() => {
        if (getViewportSize() <= BREAKPOINT_LG) setMobileMenu(false);
    }, []);

    useEffect(() => {
        if (mobileMenu === false) {
            setSubmenuMobileActive(false);
            setTimeout(() => {
                const states = navigation?.map(() => false);
                setDropdownStates(states);
            }, 300);
        }
    }, [mobileMenu, navigation]);

    return (
        <header
            ref={navRef}
            className={cx(styles.wrapper, {
                [`${styles.isMobileActive}`]: mobileMenu,
                [`${styles.isMobileSubmenuActive}`]: submenuMobileActive,
            })}
            style={{
                top: offsetTop,
                height: mobileMenu ? `calc(100vh - ${offsetTop}px)` : 'auto',
            }}
        >
            <i
                className={cx(styles.underlay, {
                    [styles.isActive]: dropdownOpen === true,
                })}
            />
            <nav className={styles.nav}>
                <ul className={cx('u-b1', styles.navList)}>
                    <li className={cx(styles.logo, styles.navListItem)}>
                        <LinkUniversal
                            to={home || '/'}
                            tpLink="internal"
                            onClick={() => closeMobileMenu()}
                        >
                            <img src={logo || defaultLogo} alt={siteTitle || ''} />
                        </LinkUniversal>
                    </li>
                    {navigation?.map((link, index) => {
                        const hasSubmenu = link?.submenu?.type;
                        const tpType =
                            link?.linkType === 'internal'
                                ? 'internal'
                                : link?.addTp && link?.url
                                ? 'external'
                                : false;
                        return (
                            <li
                                className={styles.navListItem}
                                key={`mainNav__${index}`}
                                onMouseEnter={() => mouseDropDownOpen(index)}
                                onMouseLeave={mouseDropDownClose}
                            >
                                <div className={styles.navListItemMobileElement}>
                                    <LinkUniversal
                                        to={link.url}
                                        target={link.target || '_self'}
                                        tpLink={tpType}
                                        onClick={ev => {
                                            if (link?.url) {
                                                closeMobileMenu();
                                            } else {
                                                mobileDropDownOpen(ev, index);
                                            }
                                        }}
                                    >
                                        <span>{link.label}</span>
                                        {hasSubmenu && (
                                            <Icon
                                                className={styles.navListItemDropdownIcon}
                                                name="arrow-down"
                                            />
                                        )}
                                    </LinkUniversal>
                                    {hasSubmenu && (
                                        <button
                                            type="button"
                                            className={styles.navListItemMobileBtn}
                                            onClick={ev => mobileDropDownOpen(ev, index)}
                                        >
                                            <Icon
                                                className={styles.navListItemArrowIcon}
                                                name="arrow-right"
                                            />
                                        </button>
                                    )}
                                </div>
                                {hasSubmenu && (
                                    <NavigationSubmenu
                                        isActive={dropdownStates[index]}
                                        top={link.submenu.top}
                                        bottom={link.submenu.bottom}
                                        type={link.submenu.type}
                                        content={link.submenu.content}
                                        mobileBtnBackOnClick={mobileDropDownClose}
                                        onClick={() => closeMobileMenu()}
                                    />
                                )}
                            </li>
                        );
                    })}
                </ul>
                {children && <div className={cx('u-b1', styles.navRight)}>{children}</div>}
            </nav>
            <nav className={styles.navMobile}>
                <div className={styles.navMobileCol}>
                    <button
                        onClick={ev => {
                            ev.preventDefault();
                            setMobileMenu(!mobileMenu);
                        }}
                        className={styles.hamburger}
                    >
                        <span />
                    </button>
                </div>
                <div className={styles.navMobileCol}>
                    <LinkUniversal
                        to={home || '/'}
                        tpLink="internal"
                        onClick={() => closeMobileMenu()}
                    >
                        <img
                            className={styles.navMobileLogo}
                            src={logo || defaultLogo}
                            alt={siteTitle || ''}
                        />
                    </LinkUniversal>
                </div>
                <div className={cx('u-b1', styles.navMobileCol)}>
                    <div className={styles.navMobileColRight}>
                        {mobileNavRight ? mobileNavRight : <span />}
                    </div>
                </div>
            </nav>
        </header>
    );
};

export const NavigationSubmenu = ({
    isActive,
    type,
    top,
    bottom,
    content,
    mobileBtnBackOnClick,
    onClick,
}: Submenu) => {
    const renderContent = () => {
        switch (type as submenuTypes) {
            case 'products':
                return (
                    <div className={styles.contentProducts}>
                        <div className={styles.contentFlex}>
                            {content?.map((item, index) => {
                                const label =
                                    item?.options?.navigationLabel || item?.options?.title;
                                return (
                                    <div
                                        className={styles.contentProductsCol}
                                        key={`subMenuProduct__${item.id || index}`}
                                    >
                                        {item?.productGroup?.submenuImage && (
                                            <LinkUniversal
                                                to={item.countryCodeURL}
                                                tpLink="internal"
                                                onClick={() => {
                                                    if (onClick && typeof onClick === 'function')
                                                        onClick();
                                                }}
                                            >
                                                <img
                                                    src={item?.productGroup?.submenuImage}
                                                    alt={item.options.title}
                                                />
                                            </LinkUniversal>
                                        )}

                                        <LinkUniversal
                                            to={item.countryCodeURL}
                                            tpLink="internal"
                                            onClick={() => {
                                                if (onClick && typeof onClick === 'function')
                                                    onClick();
                                            }}
                                        >
                                            <span className="u-b1">{label}</span>
                                        </LinkUniversal>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            case 'sports':
                return (
                    <div className={styles.contentAthlete}>
                        <div className={styles.contentFlex}>
                            {content?.map((athlete, index) => {
                                const label =
                                    athlete?.options?.navigationLabel || athlete?.options?.title;
                                const nationality = athlete.athletePostType.nationality;
                                let country = {
                                    code: '',
                                    name: '',
                                };
                                if (nationality && nationality.length > 1) {
                                    country = {
                                        code: nationality[0],
                                        name: nationality[1],
                                    };
                                }
                                return (
                                    <div
                                        key={`subMenuAthlete__${athlete.id || index}`}
                                        className={styles.contentAthleteCol}
                                    >
                                        <Athlete
                                            addTp
                                            lazy={false}
                                            parallax={false}
                                            title={label}
                                            link={athlete.countryCodeURL}
                                            photos={{
                                                vertical: {
                                                    sourceUrl:
                                                        athlete?.athletePostType?.photoVertical ||
                                                        '',
                                                    altText: athlete?.options?.title,
                                                    mediaDetails: {
                                                        sizes: [],
                                                    },
                                                },
                                                horizontal: {
                                                    sourceUrl:
                                                        athlete?.athletePostType?.photoHorizontal ||
                                                        '',
                                                    altText: athlete?.options?.title,
                                                    mediaDetails: {
                                                        sizes: [],
                                                    },
                                                },
                                            }}
                                            variation="big"
                                            align="center"
                                            sport={
                                                athlete.sportTypes.nodes &&
                                                athlete.sportTypes.nodes.length > 0
                                                    ? athlete.sportTypes.nodes[0].name
                                                    : ''
                                            }
                                            subtitle={athlete?.athletePostType?.subtitle || null}
                                            country={country}
                                            onClick={() => {
                                                if (onClick && typeof onClick === 'function')
                                                    onClick();
                                            }}
                                            isInNavigation
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            case 'magazines':
                return (
                    <div className={styles.contentMagazine}>
                        <div className={styles.contentFlex}>
                            {content?.map((article, index) => (
                                <div
                                    key={`subMenuMagazine__${article.id || index}`}
                                    className={styles.contentMagazineCol}
                                >
                                    <ArticleItem
                                        addTp
                                        lazy={false}
                                        link={article.countryCodeURL}
                                        image={{
                                            sourceUrl:
                                                article?.magazineSingleContent?.headlineImageCrop ||
                                                article?.magazineSingleContent?.headlineImage
                                                    ?.sourceUrl,
                                            altText:
                                                article?.magazineSingleContent?.headlineImage
                                                    ?.altText || article?.options?.title,
                                        }}
                                        title={
                                            article?.options?.navigationLabel ||
                                            article?.magazineSingleContent?.titleoverride ||
                                            article?.options?.title
                                        }
                                        date={article.date}
                                        variation="small"
                                        eyebrow={generateCategories(
                                            article?.magazineCategories?.nodes
                                        )}
                                        onClick={() => {
                                            if (onClick && typeof onClick === 'function') onClick();
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                );
            case 'productsExternal':
                return (
                    <div className={styles.contentProducts}>
                        <div className={styles.contentFlex}>
                            {content?.map((item, index) => {
                                const tpType =
                                    item?.linkType === 'internal'
                                        ? 'internal'
                                        : item?.addTp && item?.url?.url
                                        ? 'external'
                                        : false;
                                return (
                                    <div
                                        key={`subMenuProduct__${item.id || index}`}
                                        className={styles.contentProductsCol}
                                    >
                                        <LinkUniversal
                                            to={item?.url?.url}
                                            target={item?.url?.target || '_self'}
                                            tpLink={tpType}
                                            onClick={() => {
                                                if (
                                                    onClick &&
                                                    typeof onClick === 'function' &&
                                                    item?.url?.target !== '_blank'
                                                )
                                                    onClick();
                                            }}
                                        >
                                            <img
                                                src={item?.image?.sourceUrl}
                                                alt={item?.image?.altText || ''}
                                            />
                                        </LinkUniversal>
                                        <LinkUniversal
                                            to={item?.url?.url}
                                            target={item?.url?.target || '_self'}
                                            tpLink={tpType}
                                            onClick={() => {
                                                if (
                                                    onClick &&
                                                    typeof onClick === 'function' &&
                                                    item?.url?.target !== '_blank'
                                                )
                                                    onClick();
                                            }}
                                        >
                                            <span className="u-b1">
                                                {item?.linkExternalLabel || item?.url?.title}
                                            </span>
                                        </LinkUniversal>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            default:
                return false;
        }
    };

    return (
        (top?.length > 0 || bottom?.length > 0) && (
            <div
                className={cx(styles.submenu, {
                    [`${styles.submenuActive}`]: isActive,
                })}
            >
                <div className={styles.submenuLeft}>
                    {mobileBtnBackOnClick && typeof mobileBtnBackOnClick === 'function' && (
                        <button
                            type="button"
                            className={styles.submenuMobileBtnBack}
                            onClick={ev => mobileBtnBackOnClick(ev)}
                        >
                            <Icon className={styles.navListItemArrowIcon} name="arrow-left" />
                            <span>Back to list</span>
                        </button>
                    )}
                    {top?.length > 0 && (
                        <ul className={cx('u-b1', styles.submenuNav)}>
                            {top.map((item, index) => {
                                const tpType =
                                    item?.linkType === 'internal'
                                        ? 'internal'
                                        : item?.addTp && item?.url
                                        ? 'external'
                                        : false;
                                return (
                                    <li key={`subMenuTop__${index}`}>
                                        <LinkUniversal
                                            to={item.url}
                                            target={item.target || '_self'}
                                            tpLink={tpType}
                                            onClick={() => {
                                                if (onClick && typeof onClick === 'function')
                                                    onClick();
                                            }}
                                        >
                                            {item?.label}
                                        </LinkUniversal>
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                    {bottom?.length > 0 && (
                        <ul className={cx('u-b1', styles.submenuNav, styles.submenuNavRed)}>
                            {bottom.map((item, index) => {
                                const tpType =
                                    item?.linkType === 'internal'
                                        ? 'internal'
                                        : item?.addTp && item?.url
                                        ? 'external'
                                        : false;
                                return (
                                    <li key={`subMenuBottom__${index}`}>
                                        <LinkUniversal
                                            to={item.url}
                                            target={item.target || '_self'}
                                            tpLink={tpType}
                                            onClick={() => {
                                                if (onClick && typeof onClick === 'function')
                                                    onClick();
                                            }}
                                        >
                                            {item.label}
                                        </LinkUniversal>
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                </div>
                {type !== 'none' && <div className={styles.submenuRight}>{renderContent()}</div>}
            </div>
        )
    );
};

export const NavigationSideMenu = ({ children }) => (
    <ul className={styles.navSideMenu}>{children}</ul>
);

export const NavigationSideMenuItem = ({ mobile = true, inherit = true, children }) => (
    <li
        className={cx(styles.navSideMenuItem, {
            [`${styles.navSideMenuItemMobileFalse}`]: mobile === false,
            [`${styles.navSideMenuItemInherit}`]: inherit,
        })}
    >
        {children}
    </li>
);
