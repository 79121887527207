import React from 'react';
import cx from 'classnames';
import LinkUniversal from 'components/LinkUniversal';
import styles from './UnderlineLink.module.scss';

export interface UnderlineLinkProps
    extends React.HTMLAttributes<HTMLAnchorElement | HTMLButtonElement> {
    to?: string;
    className?: string;
    target?: string;
    color?: 'red' | 'white';
    tpLink?: false | 'internal' | 'external';
}

export const UnderlineLink: React.FC<UnderlineLinkProps> = ({
    children,
    to,
    className,
    target,
    color,
    tpLink,
    ...rest
}) => (
    <LinkUniversal
        to={to}
        tpLink={tpLink}
        className={cx(
            styles.underlineLink,
            {
                [styles.white]: color === 'white',
            },
            className
        )}
        target={target || '_self'}
        {...rest}
    >
        {children}
        <span className={cx(styles.line)} />
    </LinkUniversal>
);
