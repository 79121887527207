import React, { useEffect, useRef, useState, useCallback, ReactElement } from 'react';
import gsap from 'gsap';
import cx from 'classnames';
import Icon from 'components/Icons';
import styles from './Accordion.module.scss';

export interface AccordionItemProps {
    title?: any;
    description?: any;
    color?: 'beige' | 'white';
    headerFont?: string;
    panelFont?: string;
    onChange?: (state: boolean) => void;
    opened?: null | boolean;
}

export const AccordionItem = React.forwardRef<HTMLDivElement, AccordionItemProps>(
    (
        {
            title,
            description,
            color = 'beige',
            headerFont = 'u-b0 u-fw-400',
            panelFont = 'u-b1',
            onChange,
            opened = null,
        },
        ref
    ) => {
        const [isOpened, setOpened] = useState(false);
        const $accordionContent = useRef<HTMLDivElement | null>();
        const $accordionContentInner = useRef<HTMLDivElement | null>();

        const open = useCallback(() => {
            if ($accordionContent?.current) {
                const heightToAnimate = $accordionContentInner.current.offsetHeight;
                gsap.to($accordionContent.current, {
                    duration: 0.5,
                    height: heightToAnimate,
                    ease: 'power3.out',
                    onComplete: () => {
                        gsap.set($accordionContent.current, {
                            height: 'auto',
                        });
                    },
                });
            }
        }, [$accordionContentInner, $accordionContent]);

        const close = useCallback(() => {
            if ($accordionContent?.current) {
                gsap.to($accordionContent.current, {
                    height: 0,
                    duration: 0.4,
                    ease: 'power3.in',
                });
            }
        }, [$accordionContent]);

        const toggleAccordion = useCallback(
            ev => {
                if (ev) ev.preventDefault();
                if ($accordionContent?.current && $accordionContentInner?.current) {
                    if (onChange && typeof onChange === 'function') onChange(opened);
                    setOpened(!isOpened);
                }
            },
            [$accordionContent, $accordionContentInner, onChange, isOpened, opened]
        );

        useEffect(() => {
            isOpened ? open() : close();
        }, [isOpened, open, close]);

        useEffect(() => {
            if (opened !== null) {
                setOpened(opened);
            }
        }, [opened]);

        return (
            <div
                ref={ref}
                className={cx(styles.accordionItem, `accordionItemColor__${color}`, {
                    'is-opened': isOpened,
                })}
            >
                <div
                    role="banner"
                    className={styles.accordionItemHeader}
                    onClick={ev => toggleAccordion(ev)}
                >
                    <h4
                        className={cx(
                            styles.accordionHeaderTitle,
                            headerFont ? headerFont : 'u-b0 u-fw-400'
                        )}
                    >
                        {title}
                    </h4>
                    <Icon
                        name="arrow-down"
                        className={cx(styles.accordionItemIndicator, {
                            [`${styles.accordionItemIndicatorActive}`]: isOpened,
                        })}
                    />
                </div>
                <div ref={$accordionContent} className={styles.accordionPanel}>
                    <div ref={$accordionContentInner} className={styles.accordionPanelWrapper}>
                        <div className={cx(panelFont ? panelFont : 'u-b1')}>{description}</div>
                    </div>
                </div>
            </div>
        );
    }
);

interface AccordionGroupProps extends React.HTMLAttributes<HTMLDivElement> {
    content: readonly AccordionItemProps[];
    headerFont?: string;
    panelFont?: string;
    isMono?: boolean;
    color?: 'beige' | 'white';
}

export const AccordionGroup = React.forwardRef<HTMLDivElement, AccordionGroupProps>(
    (
        {
            content,
            className = '',
            headerFont = 'u-b0 u-fw-400',
            panelFont = 'u-b1',
            isMono = true,
            color = 'beige',
        },
        ref
    ) => {
        const [items, setItem] = useState(content?.reduce(acc => [...acc, false], []));

        const handleChange = useCallback(
            (state, index) => {
                const changedItems = items?.reduce((acc, item, itemIndex) => {
                    if (index === itemIndex) return [...acc, !state];
                    if (isMono) return [...acc, false];
                    return [...acc];
                }, []);
                setItem(changedItems);
            },
            [items, isMono]
        );

        return (
            <div ref={ref} className={cx(styles.accordionGroup, className || '')}>
                {content?.map((item, index) => (
                    <div className={styles.accordionGroupItem} key={index}>
                        <AccordionItem
                            title={item.title}
                            description={item.description}
                            headerFont={headerFont}
                            panelFont={panelFont}
                            color={color}
                            opened={items[index]}
                            onChange={state => handleChange(state, index)}
                        />
                    </div>
                ))}
            </div>
        );
    }
);

interface AccordionWithChildrenProps extends React.HTMLAttributes<HTMLDivElement> {
    title: string;
    eyebrow?: string | ReactElement;
}

export const AccordionWithChildren = ({ title, eyebrow, children }: AccordionWithChildrenProps) => {
    const [isActive, setActive] = useState(false);
    const $content = useRef<HTMLDivElement | null>();
    const $contentWrapper = useRef<HTMLDivElement | null>();

    const open = useCallback(() => {
        if ($contentWrapper?.current && $content?.current) {
            setActive(true);
            const heightToAnimate = $content.current.offsetHeight;
            gsap.to($contentWrapper.current, {
                duration: 0.5,
                height: heightToAnimate,
                ease: 'power3.out',
                onComplete: () => {
                    gsap.set($contentWrapper.current, {
                        height: 'auto',
                    });
                },
            });
        }
    }, [$contentWrapper, $content]);

    const close = useCallback(() => {
        if ($contentWrapper?.current) {
            setActive(false);
            gsap.to($contentWrapper.current, {
                height: 0,
                duration: 0.4,
                ease: 'power3.in',
            });
        }
    }, [$contentWrapper]);

    const toggleAccordion = useCallback(
        () => (isActive ? close() : open()),
        [isActive, close, open]
    );

    return (
        <div className={styles.accordionChildren}>
            <ul className={styles.accordionChildrenHeader}>
                <li>
                    <button
                        onClick={toggleAccordion}
                        className={cx('u-a1', styles.accordionChildrenTitle, {
                            [styles.accordionChildrenTitleActive]: isActive,
                        })}
                    >
                        <Icon name="arrow-right" />
                        <span>{title}</span>
                    </button>
                </li>
                {eyebrow && <li className="u-b1">{eyebrow}</li>}
            </ul>
            <div ref={$contentWrapper} className={styles.accordionChildrenContent}>
                <div ref={$content}>{children}</div>
            </div>
        </div>
    );
};
