import React, { useEffect, useRef } from 'react';
import cx from 'classnames';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

if (typeof window !== 'undefined') {
    gsap.registerPlugin(ScrollTrigger);
}

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    className?: string;
    percentage: number;
    start?: string;
    end?: string;
    style?: React.CSSProperties;
}

export const ParallaxElement = React.forwardRef<HTMLDivElement, Props>(
    ({ className, percentage, start, end, children, ...rest }, ref) => {
        const mergedClasses = cx('u-parallax-element', className);

        const parallaxElements = useRef(null);

        useEffect(() => {
            if (parallaxElements?.current) {
                gsap.to(parallaxElements.current, {
                    yPercent: percentage,
                    duration: 1,
                    scrollTrigger: {
                        trigger: parallaxElements.current || '',
                        scrub: 0.6,
                        start: start ? start : 'top bottom',
                        end: end ? end : '+=200%',
                    },
                });
            }
        }, [start, end, percentage, parallaxElements]);

        return (
            <>
                {children ? (
                    <div ref={parallaxElements} className={mergedClasses} {...rest}>
                        {children}
                    </div>
                ) : (
                    <div ref={parallaxElements} className={mergedClasses} {...rest} />
                )}
            </>
        );
    }
);
