import React, { Fragment } from 'react';
import Head from 'next/head';
import router from 'next/router';

interface Props {
    title?: string;
    language?: string;
    data?: {
        title?: string;
        metaDesc?: string;
        metaKeywords?: string;
        opengraphTitle?: string;
        opengraphType?: string;
        opengraphDescription?: string;
        opengraphImage?: {
            sourceUrl?: string;
            mediaDetails?: {
                height?: number;
                width?: number;
            };
        };
        twitterTitle?: string;
        twitterDescription?: string;
        twitterImage?: {
            sourceUrl?: string;
        };
    };
    hreflangs?: { language: string; url: string }[];
}

const SEO = ({ title, language, data, hreflangs }: Props) => {
    const pageTitle = data?.title ? data.title : `${title} | Fitline`;

    const pageUrl =
        router?.router?.asPath && process.env.NEXT_PUBLIC_WEBSITE
            ? `${process.env.NEXT_PUBLIC_WEBSITE}${router?.router?.asPath}`
            : null;

    return (
        <Head>
            <meta charSet="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
            <title>{pageTitle}</title>
            {data?.metaDesc && <meta property="description" content={data.metaDesc} />}
            {data?.metaDesc && <meta name="description" content={data.metaDesc} />}
            {data?.metaKeywords && <meta property="keywords" content={data.metaKeywords} />}
            <meta property="og:locale" content={language?.toLowerCase() || 'en'} />
            <meta property="og:type" content={data?.opengraphType || 'website'} />
            <meta property="og:title" content={data?.opengraphTitle || pageTitle} />
            <meta
                property="og:description"
                content={data?.opengraphDescription || data?.metaDesc || ''}
            />
            {pageUrl && <meta property="og:url" content={pageUrl} />}
            <meta property="og:site_name" content="Fitline" />
            <meta
                property="og:image"
                content={
                    data?.opengraphImage?.sourceUrl ||
                    `${process.env.NEXT_PUBLIC_WEBSITE}/img/fb-share.jpg`
                }
            />
            <meta
                property="og:image:width"
                content={data?.opengraphImage?.mediaDetails?.width?.toString() || '2400'}
            />
            <meta
                property="og:image:height"
                content={data?.opengraphImage?.mediaDetails?.height?.toString() || '1260'}
            />
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:title" content={data?.twitterTitle || pageTitle} />
            <meta
                property="twitter:description"
                content={data?.twitterDescription || data?.metaDesc || ''}
            />
            <meta
                property="twitter:image"
                content={
                    data?.twitterImage?.sourceUrl ||
                    `${process.env.NEXT_PUBLIC_WEBSITE}/img/tw-share.jpg`
                }
            />
            {pageUrl && <link rel="canonical" href={pageUrl} />}

            {hreflangs?.map(item => (
                <Fragment key={item.language}>
                    <link rel="alternate" hrefLang={item.language} href={item.url} />
                </Fragment>
            ))}
        </Head>
    );
};

export default SEO;
