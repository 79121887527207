import React from 'react';
import { ArrowLeft } from './ArrowLeft';
import { ArrowRight } from './ArrowRight';
import { ArrowUp } from './ArrowUp';
import { ArrowDown } from './ArrowDown';
import { Hamburger } from './Hamburger';
import { Quote } from './Quote';
import { Play } from './Play';
import { Close } from './Close';
import { Facebook } from './Facebook';
import { Twitter } from './Twitter';
import { Pinterest } from './Pinterest';
import { Linkedin } from './Linkedin';
import { WhatsApp } from './WhatsApp';
import { External } from './External';
import { Info } from './Info';
import { Filter } from './Filter';
import { Check } from './Check';
import { Heart } from './Heart';
import { Download } from './Download';
import { Search } from './Search';
import { RoundedArrow } from './RoundedArrow';
import { RoundedArrowFlipped } from './RoundedArrowFlipped';
import { Instagram } from './Instagram';
import { ShoppingBag } from './ShoppingBag';
import { Truck } from './Truck';
import { Cards } from './Cards';
import { Subscription } from './Subscription';
import { Clipboard } from './Clipboard';
import { Sound } from './Sound';
import { VideoIndicator } from './VideoIndicator';
import { VideoPlay } from './VideoPlay';

export type IconName =
    | null
    | 'arrow-left'
    | 'arrow-right'
    | 'arrow-up'
    | 'arrow-down'
    | 'hamburger'
    | 'quote'
    | 'play'
    | 'close'
    | 'facebook'
    | 'twitter'
    | 'pinterest'
    | 'linkedin'
    | 'whatsapp'
    | 'external'
    | 'info'
    | 'filter'
    | 'check'
    | 'shopping-bag'
    | 'cards'
    | 'subscription'
    | 'truck'
    | 'download'
    | 'search'
    | 'heart'
    | 'clipboard'
    | 'rounded-arrow'
    | 'rounded-arrow-flipped'
    | 'instagram'
    | 'sound'
    | 'video-indicator'
    | 'video-play';

const switchIcon = (name: IconName, rest: any) => {
    switch (name) {
        case 'arrow-left':
            return <ArrowLeft {...rest} />;
        case 'arrow-right':
            return <ArrowRight {...rest} />;
        case 'arrow-up':
            return <ArrowUp {...rest} />;
        case 'arrow-down':
            return <ArrowDown {...rest} />;
        case 'hamburger':
            return <Hamburger {...rest} />;
        case 'quote':
            return <Quote {...rest} />;
        case 'play':
            return <Play {...rest} />;
        case 'close':
            return <Close {...rest} />;
        case 'facebook':
            return <Facebook {...rest} />;
        case 'twitter':
            return <Twitter {...rest} />;
        case 'pinterest':
            return <Pinterest {...rest} />;
        case 'linkedin':
            return <Linkedin {...rest} />;
        case 'whatsapp':
            return <WhatsApp {...rest} />;
        case 'external':
            return <External {...rest} />;
        case 'info':
            return <Info {...rest} />;
        case 'filter':
            return <Filter {...rest} />;
        case 'check':
            return <Check {...rest} />;
        case 'search':
            return <Search {...rest} />;
        case 'heart':
            return <Heart {...rest} />;
        case 'download':
            return <Download {...rest} />;
        case 'rounded-arrow':
            return <RoundedArrow {...rest} />;
        case 'rounded-arrow-flipped':
            return <RoundedArrowFlipped {...rest} />;
        case 'instagram':
            return <Instagram {...rest} />;
        case 'truck':
            return <Truck {...rest} />;
        case 'shopping-bag':
            return <ShoppingBag {...rest} />;
        case 'cards':
            return <Cards {...rest} />;
        case 'subscription':
            return <Subscription {...rest} />;
        case 'clipboard':
            return <Clipboard {...rest} />;
        case 'sound':
            return <Sound {...rest} />;
        case 'video-indicator':
            return <VideoIndicator {...rest} />;
        case 'video-play':
            return <VideoPlay {...rest} />;
        default:
            return;
    }
};

interface Props extends React.HTMLAttributes<SVGElement> {
    name: IconName;
}

export const Icon: React.FunctionComponent<Props> = ({ name, ...rest }) => (
    <>{switchIcon(name, { ...rest })}</>
);
