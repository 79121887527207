import React from 'react';

export const Close = ({ className = '', viewBox = '0 0 16 16' }) => (
    <svg
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        className={`u-icon u-icon--close ${className || ''}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <path d="M7,8l-7,7L1,15.9l7-7L15,16l0.9-0.9L8.8,8l7.1-7.1L15,0L7.9,7.1l-7-7L0.1,1L7,8z" />
    </svg>
);
