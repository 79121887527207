import React from 'react';

export const ArrowDown = ({ className = '', viewBox = '0 0 9 6' }) => (
    <svg
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        className={`u-icon u-icon--arrow-down ${className || ''}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <path d="M.586 1.414L2 0l2.793 2.793L7.586 0 9 1.414 4.793 5.621.586 1.414z" />
    </svg>
);
