import React from 'react';
import cx from 'classnames';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    modifier?: string;
    alignment?: string;
    bgColor: string;
    className?: string;
}

export const Section = React.forwardRef<HTMLDivElement, Props>(
    ({ children, modifier = '', alignment = '', bgColor, className, ...rest }, ref) => (
        <section
            ref={ref}
            className={cx(
                'o-section',
                modifier ? `o-section--${modifier}` : '',
                alignment ? `o-section--${alignment}` : '',
                bgColor ? `o-section--${bgColor}` : '',
                className
            )}
            {...rest}
        >
            {children}
        </section>
    )
);
