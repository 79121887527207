// this file is generated from npm script
// Wed Apr 10 2024 19:33:31 GMT+0200 (Central European Summer Time)
// adding kazahstan
export default [
    'au',
    'at',
    'be',
    'bo',
    'pt-br',
    'bg',
    'ca',
    'cl',
    'cn',
    'co',
    'cr',
    'ci',
    'hr',
    'cs',
    'da',
    'do',
    'ec',
    'sv',
    'fi',
    'fr',
    'de',
    'hn',
    'zh-hant',
    'hu',
    'is',
    'in',
    'id',
    'it',
    'ja',
    'lv',
    'li',
    'kz',
    'lu',
    'my',
    'mx',
    'ma',
    'nl',
    'nz',
    'no',
    'pl',
    'pt-pr',
    'ro',
    'ru',
    'rs',
    'sg',
    'sk',
    'si',
    'ko',
    'es',
    'se',
    'ch',
    'tw',
    'th',
    'tr',
    'uk',
    'ae',
    'gb',
    'us',
    've',
    'vi',
    'zm',
];
