import React from 'react';
import { useAppContext } from 'context/App';

interface Props {
    name: string;
    fallback?: string;
}

const TranslationKey = ({ name, fallback }: Props) => {
    const { translations, language } = useAppContext();
    const translationValue = translations[language] ? translations[language][name] : null;
    if (!translationValue) {
        // console.warn(`Missing translation key/value for language ${language.toLowerCase()}: ${name}`);
    }
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{translationValue || fallback || `{{ ${name} }}`}</>;
};

const TranslationKeyString = (name = null, fallback = null) => {
    const { translations, language } = useAppContext();
    const translationValue = translations[language] ? translations[language][name] : null;
    if (!translationValue) {
        // console.warn(`Missing translation key/value for language ${language.toLowerCase()}: ${name}`);
    }
    return translationValue || fallback || `{{ ${name} }}`;
};

export { TranslationKeyString };

export default TranslationKey;
