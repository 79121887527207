import React from 'react';

export const Filter = ({ className = '', viewBox = '0 0 1024 1024' }) => (
    <svg
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        className={`u-icon u-icon--filter ${className || ''}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <path d="M0 85.333h1024v170.667h-1024v-170.667z" />
        <path d="M170.667 426.667h682.667v170.667h-682.667v-170.667z" />
        <path d="M341.333 768h341.333v170.667h-341.333v-170.667z" />
    </svg>
);
