import React from 'react';
import cx from 'classnames';
import LinkUniversal from 'components/LinkUniversal';
import CountryFlag from 'components/common/CountryFlag';
import ParallaxOvalImage from 'components/Layout/ParallaxOvalImage';
import styles from './Athlete.module.scss';

interface MediaSize {
    name: string;
    sourceUrl: string;
}

interface Props {
    addTp?: boolean;
    title: string;
    subtitle?: string;
    link: string;
    sport?: string;
    photos: {
        vertical: {
            sourceUrl: string;
            altText?: string;
            mediaDetails: {
                sizes: readonly MediaSize[];
            };
        };
        horizontal: {
            sourceUrl: string;
            altText?: string;
            mediaDetails: {
                sizes: readonly MediaSize[];
            };
        };
    };
    imageHasBorderRadius?: boolean;
    variation?: 'default' | 'big';
    align?: 'default' | 'center';
    country?: {
        code: string;
        name: string;
    };
    lazy?: boolean;
    parallax?: boolean;
    onClick?: () => void;
    isInNavigation?: boolean;
}

export const Athlete = React.forwardRef<HTMLDivElement, Props>(
    (
        {
            addTp = false,
            title,
            subtitle,
            sport,
            link,
            photos,
            imageHasBorderRadius = false,
            variation = 'default',
            align = 'default',
            country,
            lazy = true,
            parallax = true,
            onClick = null,
            isInNavigation = false,
        },
        ref
    ) => {
        const { vertical, horizontal } = photos;
        return (
            <div className={styles[align] || null} ref={ref}>
                <LinkUniversal
                    to={link}
                    tpLink={addTp ? 'internal' : false}
                    onClick={() => {
                        if (onClick && typeof onClick === 'function') onClick();
                    }}
                >
                    <div className={styles.figureDesktop}>
                        <ParallaxOvalImage
                            lazy={lazy}
                            parallax={parallax}
                            className={styles.figure}
                            hasBorderRadius={imageHasBorderRadius}
                            parallaxBackground={vertical.sourceUrl}
                        />
                    </div>
                    <div className={styles.figureMobile}>
                        <ParallaxOvalImage
                            lazy={lazy}
                            parallax={parallax}
                            className={styles.figure}
                            hasBorderRadius={imageHasBorderRadius}
                            parallaxBackground={horizontal.sourceUrl}
                        />
                    </div>
                </LinkUniversal>
                {isInNavigation ? (
                    <>
                        <p className={cx('u-a3', styles.athleteTitle)}>
                            <LinkUniversal
                                to={link}
                                tpLink={addTp ? 'internal' : false}
                                onClick={() => {
                                    if (onClick && typeof onClick === 'function') onClick();
                                }}
                            >
                                {title}
                            </LinkUniversal>
                        </p>
                        {subtitle && <p className={cx('u-b2', styles.subtitle)}>{subtitle}</p>}
                        {sport && <p className={cx('u-b2', styles.athleteSport)}>{sport}</p>}
                    </>
                ) : (
                    <>
                        <h3 className={cx('u-a3', styles.athleteTitle)}>
                            <LinkUniversal
                                to={link}
                                tpLink={addTp ? 'internal' : false}
                                onClick={() => {
                                    if (onClick && typeof onClick === 'function') onClick();
                                }}
                            >
                                {title}
                            </LinkUniversal>
                        </h3>
                        {subtitle && <h4 className={cx('u-b2', styles.subtitle)}>{subtitle}</h4>}
                        {sport && <h4 className={cx('u-b2', styles.athleteSport)}>{sport}</h4>}
                    </>
                )}
                {country && country.code && country.name && (
                    <div className={styles.country}>
                        <CountryFlag
                            code={country.code}
                            name={country.name}
                            align={align || 'default'}
                            variation="small"
                        />
                    </div>
                )}
            </div>
        );
    }
);
