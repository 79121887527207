import React, { useState } from 'react';
import cx from 'classnames';
import Icon from 'components/Icons';
import LinkUniversal from 'components/LinkUniversal';
import styles from './NavigationDropdown.module.scss';

interface Item {
    title: string;
    url?: string;
    target?: string;
    onClick?: (ev: any) => void;
}

interface DropdownProps {
    labels: string[];
    items: Item[];
}

export const NavigationDropdown = ({ labels, items }: DropdownProps) => {
    const [mobileActive, setMobileActive] = useState(false);
    return (
        <div
            className={cx(styles.main, {
                [`${styles.mobileActive}`]: mobileActive,
            })}
        >
            <ul
                className={styles.labels}
                onClick={ev => {
                    ev.preventDefault();
                    setMobileActive(!mobileActive);
                }}
            >
                {labels?.map((label, index) => (
                    <li className={styles.label} key={`navigationDropdown__${index}`}>
                        {label}
                    </li>
                ))}
                <li className={styles.label}>
                    <Icon className={styles.icon} name="arrow-down" />
                </li>
            </ul>
            {items && items.length > 0 && (
                <div className={styles.dropdown}>
                    <ul className={styles.dropdownList}>
                        {items.map((item, index) => (
                            <li
                                key={`navigationDropdownItem__${index}`}
                                className={styles.dropdownItem}
                            >
                                {item.url ? (
                                    <LinkUniversal
                                        className={styles.link}
                                        to={item.url}
                                        target={item.target || '_self'}
                                        tpLink="internal"
                                    >
                                        {item.title}
                                    </LinkUniversal>
                                ) : item.onClick && typeof item.onClick === 'function' ? (
                                    <button
                                        type="button"
                                        className={cx('u-b1', styles.link)}
                                        onClick={ev => item.onClick(ev)}
                                    >
                                        {item.title}
                                    </button>
                                ) : (
                                    <span className={styles.dropdownLabel}>{item.title}</span>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};
