import React from 'react';

export const Hamburger = ({ className = '', viewBox = '0 0 89 69' }) => (
    <svg
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        className={`u-icon u-icon--hamburger ${className || ''}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <path d="M0 10.5L88.175 10.5 88.175 0 0 0zM0 39.6L88.175 39.6 88.175 29.1 0 29.1zM0 68.7L88.175 68.7 88.175 58.2 0 58.2z" />
    </svg>
);
