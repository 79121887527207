import React from 'react';

export const ShoppingBag = ({ className = '', viewBox = '0 0 40 40' }) => (
    <svg
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        className={`u-icon u-icon--heart ${className || ''}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <path d="M32.4069 11.5914L33.984 31.4639C34.1062 33.0582 33.5542 34.6427 32.4695 35.8138C31.3847 36.9849 29.847 37.6566 28.2508 37.6566H11.75C10.1538 37.6566 8.61612 36.9849 7.53143 35.8138C6.44674 34.6428 5.8946 33.0582 6.01669 31.4666L7.59426 11.5887C7.69221 10.311 8.77503 9.30812 10.0591 9.30812H13.1741V9.16811C13.1741 5.40405 16.2364 2.3418 20.0005 2.3418C23.7645 2.3418 26.8268 5.40405 26.8268 9.16811V9.30812H29.9418C31.2259 9.30812 32.3086 10.3111 32.4069 11.5914ZM20.0005 4.41033C17.3771 4.41033 15.2427 6.54467 15.2427 9.16811V9.30812H24.7582V9.16811C24.7582 6.54474 22.6238 4.41033 20.0005 4.41033ZM28.2508 35.5881C29.2879 35.5881 30.2471 35.169 30.9518 34.4083C31.6565 33.6473 32.0011 32.6589 31.9217 31.6248L30.3447 11.7523C30.3284 11.5405 30.1515 11.3767 29.9418 11.3767H26.8268V13.9242C26.8268 14.4954 26.3637 14.9585 25.7925 14.9585C25.2213 14.9585 24.7582 14.4954 24.7582 13.9242V11.3767H15.2427V13.9242C15.2427 14.4954 14.7796 14.9585 14.2084 14.9585C13.6372 14.9585 13.1741 14.4954 13.1741 13.9242V11.3767H10.0591C9.84938 11.3767 9.67253 11.5404 9.65652 11.7495L8.07908 31.6276C7.9999 32.6589 8.34436 33.6474 9.04907 34.4083C9.75385 35.169 10.713 35.5881 11.7501 35.5881H28.2508ZM23.1973 19.8814C23.6011 19.4775 24.256 19.4775 24.6598 19.8814C25.0637 20.2853 25.0637 20.9401 24.6597 21.3441L19.3102 26.6936C19.1083 26.8956 18.8435 26.9966 18.579 26.9966C18.3144 26.9966 18.0497 26.8956 17.8477 26.6936L15.3406 24.1865C14.9366 23.7826 14.9366 23.1277 15.3406 22.7238C15.7445 22.3199 16.3994 22.3199 16.8032 22.7238L18.579 24.4996L23.1973 19.8814Z" />
    </svg>
);
