import React from 'react';

export const Info = ({ className = '', viewBox = '0 0 16 16' }) => (
    <svg
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        className={`u-icon u-icon--info ${className || ''}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <path d="M8.8 7.2H7.2V12h1.6V7.2z" />
        <path d="M8 0C3.58 0 0 3.58 0 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm0 14.4A6.409 6.409 0 011.6 8c0-3.528 2.872-6.4 6.4-6.4 3.528 0 6.4 2.872 6.4 6.4 0 3.528-2.872 6.4-6.4 6.4z" />
        <path d="M8.8 4H7.2v1.6h1.6V4z" />
    </svg>
);
