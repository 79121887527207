import React, { useCallback, useState, useEffect, useRef } from 'react';
import cx from 'classnames';
import Cookies from 'js-cookie';
import axios from 'axios';
import { debounce } from 'lodash';
import router from 'next/router';
import publicIp from 'public-ip';
import generateTPlink from 'utils/generateTpLink';
import Icon from 'components/Icons';
import { COOKIES } from 'components/CookiesGDPR';
import LinkUniversal from 'components/LinkUniversal';
import styles from './IPLanguagePicker.module.scss';
import { useAppContext } from 'context';

interface Props {
    onClose?: () => void;
    onSelectClick?: () => void;
    onResize?: (height: number) => void;
}

const IPLanguagePicker = ({ onClose, onSelectClick, onResize }: Props) => {
    const [link, setLink] = useState(null);
    const [country, setCountry] = useState(null);
    const { language, countryCode } = useAppContext();

    const $element = useRef(null);

    const setCookie = useCallback(
        value => {
            Cookies.set(COOKIES.ipLang.name, value, {
                expires: COOKIES.ipLang.expires,
            });
            if (onClose && typeof onClose === 'function') onClose();
            setLink(null);
        },
        [onClose]
    );

    const handleClose = useCallback(() => {
        Cookies.set(
            COOKIES.ipLang.name,
            `${language?.toLowerCase()}/${countryCode?.toLowerCase()}`,
            {
                expires: COOKIES.ipLang.expires,
            }
        );
        if (onClose && typeof onClose === 'function') onClose();
        setLink(null);
    }, [onClose, language, countryCode]);

    const getLink = useCallback(async (language, country) => {
        const ipAddress = await publicIp.v4();
        return axios({
            method: 'GET',
            url: '/api/geolocation',
            params: {
                ip: ipAddress,
                country: country?.toLowerCase(),
                language: language?.toLowerCase(),
            },
        });
    }, []);

    const setRedirectAndCookies = useCallback(async () => {
        const ipAddress = await publicIp.v4();
        return axios({
            method: 'GET',
            url: '/api/country-redirect',
            params: {
                ip: ipAddress,
            },
        });
    }, []);

    useEffect(() => {
        const cookie = Cookies.get(COOKIES.ipLang.name);
        const cookieIPredirect = Cookies.get(COOKIES.ipRedirect.name);
        const isHomepageEN = location?.pathname === '/en' || location?.pathname === '/en/';
        // show ip language bar
        if (!cookie && !isHomepageEN) {
            getLink(language, countryCode).then(r => {
                if (r?.data?.url) {
                    setLink(r?.data?.url);
                    setCountry(r?.data?.country_name);
                }
            });
        }
        // redirect to ip language if there is no cookie present
        else if (isHomepageEN && !cookieIPredirect) {
            setRedirectAndCookies().then(r => {
                const redirectURL = generateTPlink({ url: r?.data?.url, tp: router?.query?.TP });
                if (redirectURL) {
                    Cookies.set(COOKIES.ipRedirect.name, 'true');
                    window.location.replace(`/${redirectURL}`);
                }
            });
        }
    }, [language, country, countryCode, getLink, setRedirectAndCookies]);

    useEffect(() => {
        if (
            typeof window !== 'undefined' &&
            onResize &&
            typeof onResize === 'function' &&
            $element?.current &&
            link &&
            country
        ) {
            const handleResize = () => {
                const height = Number($element?.current?.offsetHeight || 0);
                onResize(height);
            };
            handleResize();
            const debouncedHandleResize = debounce(handleResize, 300);
            window.addEventListener('resize', debouncedHandleResize);
            return () => {
                window.removeEventListener('resize', debouncedHandleResize);
            };
        }
    }, [onResize, $element, link, country]);

    return link && country ? (
        <section ref={$element} className={styles.main}>
            <ul className={cx('u-b1', styles.list)}>
                <li className={cx(styles.blockMobile, styles.blockMobileOffset)}>
                    We can show local content for your location.{' '}
                    <span className={styles.blockMobile}>Choose your location:</span>
                </li>
                <li>
                    <LinkUniversal
                        to={`/${link}`}
                        tpLink="internal"
                        onClick={() => setCookie(link)}
                    >
                        {country}
                    </LinkUniversal>
                </li>
                {onSelectClick && typeof onSelectClick === 'function' && (
                    <li className={styles.hasPipe}>
                        <button
                            onClick={() => onSelectClick()}
                            className={cx('u-b1', styles.btnLink)}
                        >
                            Select another country
                        </button>
                    </li>
                )}
                <li className={styles.isBtnClose}>
                    <button onClick={() => handleClose()} className={cx('u-b1', styles.btnClose)}>
                        <span className={styles.btnCloseLabel}>Close</span> <Icon name="close" />
                    </button>
                </li>
            </ul>
        </section>
    ) : null;
};

export default IPLanguagePicker;
