export const data = {
    'aa|aa': 'aa|aa',
    'ae|aa': 'ae|aa',
    'at|de': 'at|de',
    'at|en': 'at|en',
    'au|en': 'au|en',
    'be|fr': 'be|fr',
    'be|nl': 'be|nl',
    'bg|bg': 'bg|bg',
    'bg|en': 'bg|en',
    'bo|en': 'bo|en',
    'bo|es': 'bo|es',
    'br|en': 'br|en',
    'br|aa': 'br|aa',
    'ca|en': 'ca|en',
    'cr|en': 'cr|en',
    'ch|fr': 'ch|fr',
    'ch|it': 'ch|it',
    'ch|de': 'ch|de',
    'ci|en': 'ci|en',
    'ci|fr': 'ci|fr',
    'cl|es': 'cl|es',
    'cl|en': 'cl|en',
    'co|en': 'co|en',
    'co|es': 'co|es',
    'cr|es': 'cr|es',
    'cz|cs': 'cz|cs',
    'de|de': 'de|de',
    'de|en': 'de|en',
    'da|da': 'dk|da',
    'da|en': 'dk|en',
    'do|en': 'do|en',
    'do|es': 'do|es',
    'ec|en': 'ec|en',
    'ec|es': 'ec|es',
    'en|en': 'en|en',
    'es|es': 'es|es',
    'es|en': 'es|en',
    'fi|aa': 'fi|aa',
    'fi|en': 'fi|en',
    'fi|fi': 'fi|fi',
    'fr|en': 'fr|en',
    'fr|fr': 'fr|fr',
    'gb|en': 'gb|en',
    'gr|el': 'gr|el',
    'zh-hant|en': 'hk|en',
    'zh-hant|zh-hant': 'hk|zh',
    'hn|en': 'hn|en',
    'hn|es': 'hn|es',
    'hu|en': 'hu|en',
    'hu|hu': 'hu|hu',
    'ic|es': 'ic|es',
    'ic|en': 'ic|en',
    'id|id': 'id|id',
    'id|en': 'id|en',
    'il|il': 'il|il',
    'in|in': 'in|in',
    'is|en': 'is|en',
    'it|it': 'it|it',
    'it|en': 'it|en',
    'ja|ja': 'jp|ja',
    'kr|kr': 'kr|kr',
    'lu|fr': 'lu|fr',
    'lu|de': 'lu|de',
    'lv|lv': 'lv|lv',
    'ma|en': 'ma|en',
    'ma|fr': 'ma|fr',
    'mx|es': 'mx|es',
    'my|en': 'my|en',
    'nl|nl': 'nl|nl',
    'no|en': 'no|en',
    'no|no': 'no|no',
    'nz|en': 'nz|en',
    'ph|ph': 'ph|ph',
    'pl|pl': 'pl|pl',
    'pt-pr|pt-pt': 'pt|pt',
    'pt-br|pt-br': 'pt|pt',
    'pt-br|en': 'pt|en',
    'pt-pr|en': 'pt|en',
    'pt-pt|pt-br': 'pt|pt',
    'ro|ro': 'ro|ro',
    'ru|ru': 'ru|ru',
    'ru|en': 'ru|en',
    'sv|en': 'se|en',
    'sv|sv': 'se|sv',
    'sg|en': 'sg|en',
    'sk|sk': 'sk|sk',
    'th|th': 'th|th',
    'th|en': 'th|en',
    'tr|en': 'tr|en',
    'tr|tr': 'tr|tr',
    'tw|en': 'tw|en',
    'tw|zh-hant': 'tw|zh',
    'uk|uk': 'ua|uk',
    'uk|en': 'ua|en',
    'ua|ru': 'ua|ru',
    'us|pl': 'us|pl',
    'us|es': 'us|es',
    'us|en': 'us|en',
    've|en': 've|en',
    've|es': 've|es',
    'vn|en': 'vn|en',
    'vn|vi': 'vn|vi',
    'vi|vi': 'vi|vi',
    'zz|zz': 'zz|zz',
    'zz|en': 'zz|en',
};

export const countries = {
    aa: 'aa',
    ae: 'ae',
    at: 'at',
    au: 'au',
    be: 'be',
    bg: 'bg',
    bo: 'bo',
    br: 'br',
    ca: 'ca',
    ch: 'ch',
    ci: 'ci',
    cl: 'cl',
    co: 'co',
    cs: 'cz',
    cr: 'cr',
    cz: 'cz',
    de: 'de',
    da: 'dk',
    do: 'do',
    ec: 'ec',
    en: 'en',
    es: 'es',
    fi: 'fi',
    fr: 'fr',
    gb: 'gb',
    gr: 'gr',
    'zh-hant': 'hk',
    hn: 'hn',
    hu: 'hu',
    ic: 'ic',
    id: 'id',
    il: 'il',
    in: 'in',
    is: 'is',
    it: 'it',
    ja: 'jp',
    kr: 'kr',
    ko: 'kr',
    kz: 'kz',
    lu: 'lu',
    lv: 'lv',
    ma: 'ma',
    mx: 'mx',
    my: 'my',
    nl: 'nl',
    no: 'no',
    nz: 'nz',
    ph: 'ph',
    pl: 'pl',
    'pt-pr': 'pt',
    'pt-br': 'br',
    'pt-pt': 'pt',
    ro: 'ro',
    ru: 'ru',
    sv: 'se',
    sg: 'sg',
    sk: 'sk',
    th: 'th',
    tr: 'tr',
    tw: 'tw',
    uk: 'ua',
    ua: 'ua',
    us: 'us',
    ve: 've',
    vi: 'vi',
    vn: 'vn',
    zz: 'zz',
};

const getPMIcode = (countryCode = 'INT', language: string) => {
    const cc = countryCode?.toLowerCase();
    const lang = language?.toLowerCase();
    const results = data[`${cc}|${lang}`];

    if (cc === 'int' && cc !== null) {
        return {
            lang: 'en',
        };
    } else if (results) {
        return {
            country: results.split('|')[0],
            lang: results.split('|')[1],
        };
    } else {
        return {};
    }
};

export const getMappedCountry = (countryCode: string) => {
    const cc = countryCode?.toLowerCase();
    const alpha2code = countries[`${cc}`];
    if (alpha2code) return alpha2code;
    return countryCode?.toLowerCase();
};

export const generateCountryCode = (countryCode: string) => {
    const cc = countryCode?.toLowerCase();
    const alpha2code = countries[`${cc}`];
    if (alpha2code) return countryCode;
    return 'int';
};

export const isSlugCountryCode = (countryCode: string) => {
    const cc = countryCode?.toLowerCase();
    const alpha2code = countries[`${cc}`];
    return !!alpha2code;
};

export default getPMIcode;
