import React from 'react';

export const Check = ({ className = '', viewBox = '0 0 1024 1024' }) => (
    <svg
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        className={`u-icon u-icon--check ${className || ''}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <path d="M1024 247.202l-642.010 642.061-381.99-382.021 113.932-112.464 268.058 268.080 528.079-528.121 113.931 112.466z" />
    </svg>
);
