import React from 'react';
import cx from 'classnames';
import Container from 'components/Layout/Container';
import LinkUniversal from 'components/LinkUniversal';
import { tpLinkType } from 'utils/generateTpLink';
import styles from './Footer.module.scss';
import ReplaceTranslation from '@root/components/common/ReplaceTranslation';
import moment from 'moment';

interface Navigation {
    addTp?: boolean;
    linkType?: string;
    label: string;
    url: string;
    target: string;
}

interface Logos {
    title: string;
    items: {
        addTp: boolean;
        image: { altText: string; sourceUrl: string };
        url?: string;
    }[];
}

export type Props = {
    home?: string;
    logo?: string;
    navigation?: Navigation[][];
    logos?: Logos;
    copyright: string;
    bottomNavigation?: Navigation[];
};

export const Footer = ({ home, logo, navigation, logos, copyright, bottomNavigation }: Props) => (
    <footer className={styles.footer}>
        <Container type="wide">
            <div className={styles.top}>
                <figure className={styles.logo}>
                    <LinkUniversal to={home || '/'} tpLink="internal">
                        <img src={logo || '/logo-footer.svg'} alt="FitLine" />
                    </LinkUniversal>
                </figure>
                <div className={styles.col}>
                    {logos?.items?.length > 0 && (
                        <div className={cx('u-b1', styles.nav)}>
                            <span>{logos.title}</span>
                            <ul className={styles.logos}>
                                {logos?.items?.map((item, index) => {
                                    if (item.url) {
                                        return (
                                            <li key={`footerLogo__${index}`}>
                                                <LinkUniversal
                                                    to={item.url}
                                                    target="_blank"
                                                    tpLink={item?.addTp ? 'external' : false}
                                                >
                                                    <img
                                                        src={item.image.sourceUrl}
                                                        alt={item.image.altText || ''}
                                                    />
                                                </LinkUniversal>
                                            </li>
                                        );
                                    } else {
                                        return (
                                            <li key={`footerLogo__${index}`}>
                                                <img
                                                    src={item.image.sourceUrl}
                                                    alt={item.image.altText || ''}
                                                />
                                            </li>
                                        );
                                    }
                                })}
                            </ul>
                        </div>
                    )}
                    {navigation?.map((nav, index) => (
                        <ul key={`navCol__${index}`} className={cx('u-b1', styles.nav)}>
                            {nav.map((link, index) => (
                                <li className={styles.navLink} key={`footerNav__${index}`}>
                                    <LinkUniversal
                                        to={link.url}
                                        target={link.target}
                                        tpLink={tpLinkType(link.addTp, link.target)}
                                    >
                                        {link.label}
                                    </LinkUniversal>
                                </li>
                            ))}
                        </ul>
                    ))}
                </div>
            </div>
            <div className={cx('u-b3', styles.bottom)}>
                <ul className={styles.bottomNav}>
                    {copyright && (
                        <li>
                            <ReplaceTranslation
                                value={copyright}
                                data={{ __YEAR__: moment().format('Y') }}
                            />
                        </li>
                    )}
                    {bottomNavigation?.map((link, index) => (
                        <li key={`footerBottomNav__${index}`}>
                            <LinkUniversal
                                to={link.url}
                                target={link.target}
                                tpLink={tpLinkType(link.addTp, link.target)}
                            >
                                {link.label}
                            </LinkUniversal>
                        </li>
                    ))}
                </ul>
            </div>
        </Container>
    </footer>
);
