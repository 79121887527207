import React from 'react';

export const Subscription = ({ className = '', viewBox = '0 0 36 40' }) => (
    <svg
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        className={`u-icon u-icon--subscription ${className || ''}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <path d="M35.62 24.285l-2.8-5.499a.782.782 0 00-.696-.427H3.878a.782.782 0 00-.696.427l-2.8 5.5a.782.782 0 00.696 1.136h.976v13.797c0 .432.35.782.782.782h30.328c.432 0 .782-.35.782-.782V25.422h.977a.781.781 0 00.697-1.137zm-3.975-4.362l2.003 3.936H21.28l-2.004-3.936h12.369zm-27.287 0h12.368l-2.004 3.936H2.354l2.004-3.936zm28.024 18.514h-13.6v-4.332a.782.782 0 00-1.563 0v4.332H3.618V25.422H15.2a.782.782 0 00.697-.427l1.322-2.596v4.687a.782.782 0 001.563 0V22.4l1.321 2.596a.781.781 0 00.697.427h11.581v13.015z" />
        <path d="M11.318 34.156h-4.45a.782.782 0 000 1.563h4.45a.782.782 0 000-1.563zM18 16.06c4.423 0 8.021-3.603 8.021-8.031C26.021 3.602 22.423 0 18 0c-4.424 0-8.023 3.602-8.023 8.029 0 4.428 3.599 8.031 8.023 8.031zm0-14.497c3.56 0 6.458 2.9 6.458 6.466 0 3.566-2.897 6.468-6.458 6.468-3.562 0-6.46-2.902-6.46-6.468 0-3.565 2.898-6.466 6.46-6.466z" />
        <path d="M15.88 9.16a.676.676 0 00.956.955L18 8.951l1.164 1.164a.676.676 0 00.956-.956l-1.164-1.164 1.166-1.166a.676.676 0 00-.956-.956L18 7.039l-1.166-1.166a.676.676 0 00-.957.956l1.167 1.166L15.88 9.16z" />
        <path d="M2.645 8.811h3.382a.782.782 0 000-1.563H2.645a.782.782 0 000 1.563zM2.28 3.372l3.382 1.783a.782.782 0 00.73-1.383L3.01 1.99a.782.782 0 10-.73 1.383zM1.957 13.742a.781.781 0 001.056.327l3.382-1.781a.782.782 0 00-.728-1.383l-3.382 1.781a.782.782 0 00-.328 1.056zM29.973 8.811h3.382a.782.782 0 000-1.563h-3.382a.782.782 0 000 1.563zM29.97 5.246c.123 0 .248-.03.364-.09l3.382-1.784a.782.782 0 10-.73-1.383l-3.381 1.784a.782.782 0 00.365 1.473zM29.609 12.288l3.382 1.781a.779.779 0 001.056-.327.782.782 0 00-.328-1.056l-3.382-1.781a.782.782 0 10-.728 1.383zM18 29.813a.782.782 0 00-.781.781v.005c0 .431.35.779.781.779a.784.784 0 000-1.566z" />
    </svg>
);
