import React from 'react';

export const Linkedin = ({ className = '', viewBox = '0 0 16 16' }) => (
    <svg
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        className={`u-icon u-icon--close ${className || ''}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <path
            d="M16,16L16,16L16,16l0-5.9C16,7.3,15.4,5,12,5c-1.6,0-2.7,0.9-3.1,1.7h0V5.3H5.7V16H9v-5.3C9,9.3,9.2,8,11,8
		c1.7,0,1.7,1.6,1.7,2.8V16H16z"
        />
        <path d="M0.3,5.3h3.3V16H0.3V5.3z" />
        <path d="M1.9,0C0.9,0,0,0.9,0,1.9C0,3,0.9,3.9,1.9,3.9C3,3.9,3.8,3,3.8,1.9C3.8,0.9,3,0,1.9,0L1.9,0z" />
    </svg>
);
