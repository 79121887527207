import React from 'react';

export const Search = ({ className = '', viewBox = '0 0 13 13' }) => (
    <svg
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        className={`u-icon u-icon--search ${className || ''}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <path d="M12.9207 12.1018L9.14426 8.45516C9.86053 7.60127 10.2917 6.5157 10.2917 5.33377C10.2917 2.59387 7.98324 0.364746 5.14583 0.364746C2.30842 0.364746 0 2.59387 0 5.33377C0 8.07368 2.30845 10.3028 5.14586 10.3028C6.36984 10.3028 7.49404 9.88644 8.37832 9.1948L12.1547 12.8414C12.2605 12.9436 12.432 12.9436 12.5378 12.8414L12.9207 12.4717C13.0264 12.3695 13.0264 12.2039 12.9207 12.1018ZM5.14586 9.25669C2.90566 9.25669 1.08334 7.49699 1.08334 5.33377C1.08334 3.17056 2.90566 1.41086 5.14586 1.41086C7.38605 1.41086 9.20837 3.17056 9.20837 5.33377C9.20837 7.49699 7.38605 9.25669 5.14586 9.25669Z" />
    </svg>
);
