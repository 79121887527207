import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import { createContext, useContext } from 'react';

export type Props = {
    language: string;
    countryCode: string;
    loading: boolean;
    translations?: Record<string, string>;
    cookies?: {
        opened?: boolean | null;
        setOpened?: any;
    };
    children?: ReactNode;
};

const AppContext = createContext<Props>({
    language: 'en',
    countryCode: 'int',
    translations: {},
    loading: false,
    cookies: {
        opened: null,
        setOpened: null,
    },
});

const AppWrapper = ({
    language,
    countryCode,
    loading,
    translations = {},
    cookies,
    children,
}: Props) => (
    <AppContext.Provider value={{ language, countryCode, translations, loading, cookies }}>
        {children}
    </AppContext.Provider>
);

export const useAppContext = () => useContext(AppContext);

AppWrapper.defaultProps = {
    language: 'en',
    countryCode: 'int',
    loading: false,
    cookies: {
        opened: null,
        setOpened: null,
    },
};

AppWrapper.propTypes = {
    language: PropTypes.string,
    countryCode: PropTypes.string,
    loading: PropTypes.bool,
    cookies: PropTypes.oneOfType([PropTypes.object]),
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default AppWrapper;
