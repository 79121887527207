import React from 'react';
import cx from 'classnames';
import Icon, { IconName } from 'components/Icons';
import LinkUniversal from 'components/LinkUniversal';
import styles from './Button.module.scss';

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
    type?: 'button' | 'submit' | 'reset';
    styleType?: 'filled' | 'transparent';
    color?: 'red' | 'white';
    buttonWidth?: 'normal' | 'full-width' | 'xsmall';
    buttonHeight?: 'normal' | 'small' | 'xsmall';
    disabled?: boolean;
    to?: string;
    target?: string;
    icon?: IconName;
    tpLink?: false | 'internal' | 'external';
}

export const Button = React.forwardRef<HTMLButtonElement | HTMLAnchorElement, Props>(
    (
        {
            children,
            type = 'button',
            styleType = 'filled',
            color = 'red',
            buttonWidth = 'normal',
            buttonHeight = 'normal',
            icon = null,
            className,
            to,
            target,
            tpLink,
            ...rest
        },
        ref
    ) => {
        const mergedClasses = cx(
            styles.button,
            {
                [styles.transparent]: styleType === 'transparent',
                [styles.white]: color === 'white',
                [styles.fullWidth]: buttonWidth === 'full-width',
                [styles.widthXsmall]: buttonWidth === 'xsmall',
                [styles.small]: buttonHeight === 'small',
                [styles.heightXsmall]: buttonHeight === 'xsmall',
                'u-b2': buttonHeight === 'xsmall',
            },
            className
        );

        if (to) {
            return (
                <LinkUniversal
                    to={to}
                    tpLink={tpLink}
                    ref={ref as React.RefObject<HTMLAnchorElement>}
                    className={mergedClasses}
                    target={target || ''}
                    {...rest}
                >
                    {icon ? (
                        <>
                            <span>{children}</span>
                            <Icon className={cx(styles.icon)} name={icon} />
                        </>
                    ) : (
                        children
                    )}
                </LinkUniversal>
            );
        }

        return (
            <button
                ref={ref as React.RefObject<HTMLButtonElement>}
                className={mergedClasses}
                {...rest}
            >
                {icon ? (
                    <>
                        <span>{children}</span>
                        <Icon className={cx(styles.icon)} name={icon} />
                    </>
                ) : (
                    children
                )}
            </button>
        );
    }
);
