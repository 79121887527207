import React from 'react';

export const ArrowUp = ({ className = '', viewBox = '0 0 9 6' }) => (
    <svg
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        className={`u-icon u-icon--arrow-up ${className || ''}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <path d="M8.414 4.207L7 5.621 4.207 2.828 1.414 5.621 0 4.207 4.207 0l4.207 4.207z" />
    </svg>
);
