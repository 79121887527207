import CookiesGDPR from './CookiesGDPR';
import Cookies from 'js-cookie';
import ReactUA from 'react-ga';
import ReactGA4 from 'react-ga4';
import TagManager from 'react-gtm-module';

// 1 day = 1440min
// 1 day / (1440min / target min)
// expires in 30 minutes

export const COOKIES = {
    // required
    global: {
        name: 'viewed_cookie_policy',
        expires: 365, // days
        description:
            'The cookie is set by the GDPR Cookie Consent plugin and is used to store whether or not user has consented to the use of cookies. It does not store any personal data.',
    },
    ipLang: {
        name: 'language-preference',
        expires: 365, // days
        description: 'Cookie used to store user language preference options.',
    },
    ipRedirect: {
        name: 'ip_redirect',
        expires: 1 / (1440 / 60), // 60 minutes, but it was set to session cookie
        description: 'Cookie used to redirect from /en page to the matching country.',
    },
    instagram: {
        name: 'fitline-instagram',
        expires: 1 / (1440 / 30), // 30 minutes
        description: 'Cookie used to save bandwith on Instagram requests (images fetching).',
    },
    // optional
    googleAnalytics: {
        name: 'gdpr-google-analytics',
        expires: 365, // days
        description:
            'This cookie is set by GDPR Cookie Consent modal. The cookie is used to store the user consent for the cookies in the category "Analytics".',
    },
    googleTagManager: {
        name: 'gdpr-google-tagmanager',
        expires: 365, // days
        description:
            'This cookie is set by GDPR Cookie Consent modal. The cookie is used to store the user consent for the cookies in the category "Analytics".',
    },
    facebookPixel: {
        name: 'gdpr-facebook-pixel',
        expires: 365, // days
        description:
            'This cookie is set by GDPR Cookie Consent modal. The cookie is used to store the user consent for the cookies in the category "Analytics".',
    },
};

export default CookiesGDPR;

export const initializeTrackings = (asPath: string) => {
    // Google Analytics
    if (
        Cookies.get(COOKIES.googleAnalytics.name) === 'true' &&
        (process.env.NEXT_PUBLIC_GOOGLE_UA_TRACKING_ID ||
            process.env.NEXT_PUBLIC_GOOGLE_GA4_TRACKING_ID)
    ) {
        // UA
        if (process.env.NEXT_PUBLIC_GOOGLE_UA_TRACKING_ID) {
            ReactUA.initialize(process.env.NEXT_PUBLIC_GOOGLE_UA_TRACKING_ID);

            if (process.env.NODE_ENV === 'development') {
                console.info('Initialize Google UA');
            }
        }

        // GA4
        if (process.env.NEXT_PUBLIC_GOOGLE_GA4_TRACKING_ID) {
            ReactGA4.initialize(process.env.NEXT_PUBLIC_GOOGLE_GA4_TRACKING_ID);

            if (process.env.NODE_ENV === 'development') {
                console.info('Initialize Google GA4');
            }
        }

        // send page view
        sendPageView(asPath);
    }

    // google tag manager
    if (
        Cookies.get(COOKIES.googleTagManager.name) === 'true' &&
        process.env.NEXT_PUBLIC_GOOGLE_GTM_TRACKING_ID
    ) {
        const tags = process.env.NEXT_PUBLIC_GOOGLE_GTM_TRACKING_ID.toString().split(',') || [];

        if (tags.length > 0) {
            tags.forEach((tag: string) => {
                if (process.env.NODE_ENV === 'development') {
                    console.log(`Initialize Google Tag Manager with ID: ${tag}`);
                }

                TagManager.initialize({
                    gtmId: tag,
                });
            });
        }
    }

    //
};

export const sendPageView = (asPath: string) => {
    if (
        Cookies.get(COOKIES.googleAnalytics.name) === 'true' &&
        (process.env.NEXT_PUBLIC_GOOGLE_UA_TRACKING_ID ||
            process.env.NEXT_PUBLIC_GOOGLE_GA4_TRACKING_ID) &&
        typeof window !== 'undefined'
    ) {
        // UA
        if (process.env.NEXT_PUBLIC_GOOGLE_UA_TRACKING_ID) {
            ReactUA.pageview(`${window.location.origin}${asPath}`);
        }

        // GA4
        if (process.env.NEXT_PUBLIC_GOOGLE_GA4_TRACKING_ID) {
            ReactGA4.send({ hitType: 'pageview', page: asPath });
        }

        if (process.env.NODE_ENV === 'development') {
            console.info(`Send pageview for: ${asPath}`);
        }
    }
};
