import React, { useState } from 'react';
import cx from 'classnames';
import Moment from 'react-moment';
import { imagesLoaded } from 'utils/imagesLoaded';
import LinkUniversal from 'components/LinkUniversal';
import styles from './ArticleItem.module.scss';

type Props = {
    addTp?: boolean;
    link: string;
    image: {
        sourceUrl: string;
        altText?: string;
    };
    eyebrow?: string;
    title: string;
    date: string;
    dateFormat?: string;
    variation?: 'big' | 'small';
    lazy?: boolean;
    onClick?: () => void;
};

export const ArticleItem = React.forwardRef<HTMLDivElement, Props>(
    (
        {
            addTp = false,
            link,
            image,
            eyebrow,
            title,
            date,
            dateFormat = 'MMMM D, YYYY',
            variation,
            lazy = true,
            onClick = null,
        },
        ref
    ) => {
        const _variation = variation || 'small';
        const [hovered, setHovered] = useState<boolean>(false);

        return (
            <article className={styles.article} ref={ref}>
                <div
                    className={cx(styles.figure, {
                        [styles.figureHovered]: hovered,
                    })}
                >
                    {lazy ? (
                        <figure className="u-lazy js-lazy" data-bg={image?.sourceUrl}>
                            <LinkUniversal
                                to={link}
                                tpLink={addTp ? 'internal' : false}
                                onClick={() => {
                                    if (onClick && typeof onClick === 'function') onClick();
                                }}
                            >
                                <img
                                    src={image?.sourceUrl}
                                    alt={image?.altText || title}
                                    onLoad={imagesLoaded}
                                />
                            </LinkUniversal>
                        </figure>
                    ) : (
                        <figure
                            style={{
                                backgroundImage: `url('${image.sourceUrl}')`,
                            }}
                        >
                            <LinkUniversal
                                to={link}
                                tpLink={addTp ? 'internal' : false}
                                onClick={() => {
                                    if (onClick && typeof onClick === 'function') onClick();
                                }}
                            >
                                <img
                                    src={image.sourceUrl}
                                    alt={image.altText || title}
                                    onLoad={imagesLoaded}
                                />
                            </LinkUniversal>
                        </figure>
                    )}
                </div>
                {eyebrow && <p className={cx(styles.eyebrow, 'u-b2')}>{eyebrow}</p>}
                <h2
                    className={cx(styles.title, {
                        'u-a2': _variation === 'small',
                        [styles.titleSmall]: _variation === 'small',
                        'u-a3': _variation === 'big',
                        [styles.titleBig]: _variation === 'big',
                    })}
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                >
                    <LinkUniversal
                        to={link}
                        tpLink={addTp ? 'internal' : false}
                        onClick={() => {
                            if (onClick && typeof onClick === 'function') onClick();
                        }}
                    >
                        {title}
                    </LinkUniversal>
                </h2>
                <Moment className={cx('u-b1', styles.date)} format={dateFormat} date={date} />
            </article>
        );
    }
);
