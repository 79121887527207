import { ArticleItem } from './ArticleItem';
import { MagazineCategory } from 'types/wordpress';

export default ArticleItem;

export const generateCategories = (categories: readonly Pick<MagazineCategory, 'name'>[]) =>
    categories?.reduce((acc, item, index) => {
        if (index === 0) {
            return item?.name;
        }
        return `${acc} | ${item?.name}`;
    }, '');
