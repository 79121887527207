export const BREAKPOINT_SM = 480;
export const BREAKPOINT_SM_MD = 600;
export const BREAKPOINT_MD = 800;
export const BREAKPOINT_LG = 1140;
export const BREAKPOINT_LG_XL = 1280;
export const BREAKPOINT_XL = 1400;
export const BREAKPOINT_XXL = 1800;

export default {
    SM: BREAKPOINT_SM,
    SM_MD: BREAKPOINT_SM_MD,
    MD: BREAKPOINT_MD,
    LG: BREAKPOINT_LG,
    LG_XL: BREAKPOINT_LG_XL,
    XL: BREAKPOINT_XL,
    XXL: BREAKPOINT_XXL,
};
