import React from 'react';

export const External = ({ className = '', viewBox = '0 0 9 9' }) => (
    <svg
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        className={`u-icon u-icon--external ${className || ''}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <path d="M8.343 0H1.777v1.313h4.981L0 8.071.929 9l6.758-6.758v4.98H9V0h-.657z" />
    </svg>
);
