import React, { useState, useCallback, useEffect } from 'react';
import cx from 'classnames';
import Modal from 'react-modal';
import Cookies from 'js-cookie';
import { COOKIES } from './index';
import { truncate } from 'lodash';
import router from 'next/router';
import TranslationKey from 'context/TranslationKey';
import LinkUniversal from 'components/LinkUniversal';
import UnderlineLink from 'components/common/UnderlineLink';
import Button from 'components/common/Button';
import Icon from 'components/Icons';
import { AccordionWithChildren } from 'components/common/Accordion';
import { FrontOption_Optionsacf_Cookies_Analytics_Table } from 'types/wordpress';
import styles from './CookiesGDPR.module.scss';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    settingsModal?: {
        opened: boolean | null;
        setOpened?: any;
    };
    cookiesData: any;
}

const CookiesGDPR = ({ settingsModal, cookiesData }: Props) => {
    const TRUNCATE_LENGTH = 260;

    const [visible, setVisible] = useState(false);
    const [settings, setSettings] = useState(false);
    const [allCookies, setAllCookies] = useState(false);
    const [truncateLength, setTruncateLength] = useState(TRUNCATE_LENGTH);

    const toggleSettings = useCallback(ev => {
        ev?.preventDefault();
        setSettings(true);
        setVisible(false);
    }, []);

    const handleModalClose = useCallback(
        (all = false) => {
            if (
                settingsModal?.opened !== null &&
                settingsModal?.setOpened &&
                typeof settingsModal?.setOpened === 'function'
            ) {
                settingsModal.setOpened(false);
            } else {
                setSettings(false);
                setVisible(!all);
            }
        },
        [settingsModal]
    );

    const toggleMore = useCallback(
        length => {
            setTruncateLength(truncateLength === TRUNCATE_LENGTH ? length : TRUNCATE_LENGTH);
        },
        [truncateLength]
    );

    const toogleCookies = useCallback((state: boolean) => {
        const stateAsString = state ? 'true' : 'false';
        // set google analytics cookie
        Cookies.set(COOKIES.googleAnalytics.name, stateAsString, {
            expires: COOKIES.googleAnalytics.expires,
        });
        // set google tag manager cookie
        Cookies.set(COOKIES.googleTagManager.name, stateAsString, {
            expires: COOKIES.googleTagManager.expires,
        });
        // set facebook pixel cookie
        Cookies.set(COOKIES.facebookPixel.name, stateAsString, {
            expires: COOKIES.facebookPixel.expires,
        });
        // set state
        setAllCookies(state);
    }, []);

    const acceptCookies = useCallback(
        (all = true) => {
            Cookies.set(COOKIES.global.name, 'true', {
                expires: COOKIES.global.expires,
            });

            // toogle all cookies
            toogleCookies(all);

            // hide all modals
            handleModalClose(true);

            // reload page to initialize tracking
            router.reload();
        },
        [toogleCookies, handleModalClose]
    );

    const checkActiveCookiesAndSetToogle = useCallback(() => {
        if (
            Cookies.get(COOKIES.googleAnalytics.name) === 'true' ||
            Cookies.get(COOKIES.googleTagManager.name) === 'true' ||
            Cookies.get(COOKIES.facebookPixel.name) === 'true'
        ) {
            setAllCookies(true);
        }
    }, []);

    useEffect(() => {
        if (settingsModal?.opened !== null) {
            checkActiveCookiesAndSetToogle();
            setSettings(settingsModal.opened);
        }
    }, [settingsModal, checkActiveCookiesAndSetToogle]);

    useEffect(() => {
        const cookie = Cookies.get(COOKIES.global.name);
        if (!cookie) setVisible(true);
    }, []);

    return (
        <>
            {cookiesData?.initial && (
                <section
                    className={cx(styles.initial, {
                        [styles['initial--active']]: visible,
                    })}
                >
                    <div className={styles.initialBox}>
                        <span className={cx('u-a3', styles.initialTitle)}>
                            {cookiesData?.initial?.title}
                        </span>
                        <p className={cx('u-b1', styles.initialText)}>
                            {cookiesData?.initial?.description}{' '}
                            {cookiesData?.initial?.privacyPage?.url && (
                                <LinkUniversal
                                    to={cookiesData.initial.privacyPage.url}
                                    tpLink="internal"
                                >
                                    {cookiesData.initial.privacyPage.label.toUpperCase()}
                                </LinkUniversal>
                            )}
                        </p>
                        <ul className={styles.initialButtons}>
                            <li>
                                <UnderlineLink className="u-b1" onClick={ev => toggleSettings(ev)}>
                                    {cookiesData.initial.settings}
                                </UnderlineLink>
                            </li>
                            <li>
                                <Button
                                    className="u-b1"
                                    buttonHeight="small"
                                    buttonWidth="xsmall"
                                    onClick={() => acceptCookies(true)}
                                >
                                    {cookiesData.initial.button}
                                </Button>
                            </li>
                        </ul>
                    </div>
                </section>
            )}

            {cookiesData?.overview && (
                <Modal
                    isOpen={settings}
                    closeTimeoutMS={300}
                    className={cx(styles.modalMain, {
                        [styles['modalMain--opened']]: settings,
                        [styles['modalMain--closed']]: !settings,
                    })}
                    overlayClassName={cx(styles.modalOverlay, {
                        [styles['modalOverlay--opened']]: settings,
                        [styles['modalOverlay--closed']]: !settings,
                    })}
                >
                    <div className={cx(styles.container)}>
                        <button className={styles.btnClose} onClick={() => handleModalClose()}>
                            <Icon name="close" className={styles.btnIcon} />
                        </button>
                        <span className={cx('u-a3', styles.modalTitle)}>
                            {cookiesData?.overview?.title}
                        </span>
                        {cookiesData?.overview?.description && (
                            <>
                                <p className={cx('u-b3', styles.truncatedText)}>
                                    {truncate(cookiesData.overview.description, {
                                        length: truncateLength,
                                        separator: ' ',
                                    })}
                                </p>
                                {cookiesData?.overview?.showLessButton &&
                                    cookiesData?.overview?.showMoreButton && (
                                        <button
                                            className={cx('u-b3', styles.truncateBtn)}
                                            onClick={() =>
                                                toggleMore(cookiesData.overview.description.length)
                                            }
                                        >
                                            {truncateLength ===
                                            cookiesData.overview.description.length
                                                ? cookiesData.overview.showLessButton
                                                : cookiesData.overview.showMoreButton}
                                        </button>
                                    )}
                            </>
                        )}
                        {(cookiesData?.necessary || cookiesData?.analytics) && (
                            <div className={styles.accordions}>
                                {cookiesData?.necessary?.title && (
                                    <div className={styles.accordionsItem}>
                                        <AccordionWithChildren
                                            title={cookiesData.necessary.title}
                                            eyebrow={cookiesData?.necessary.eyebrow}
                                        >
                                            <div className={styles.accordionsContent}>
                                                {cookiesData?.necessary?.description && (
                                                    <p className="u-b3">
                                                        {cookiesData.necessary.description}
                                                    </p>
                                                )}
                                                <CookiesGDPRTable
                                                    table={cookiesData?.necessary?.table}
                                                />
                                            </div>
                                        </AccordionWithChildren>
                                    </div>
                                )}
                                {cookiesData?.analytics?.title && (
                                    <div className={styles.accordionsItem}>
                                        <AccordionWithChildren
                                            title={cookiesData.analytics.title}
                                            eyebrow={
                                                <button
                                                    type="button"
                                                    onClick={() => toogleCookies(!allCookies)}
                                                    className={cx('u-b1', styles.btnToggle)}
                                                >
                                                    <TranslationKey
                                                        name={allCookies ? 'enabled' : 'disabled'}
                                                    />
                                                    <span
                                                        className={cx(styles.btnToggleRadio, {
                                                            [styles.btnToggleRadioActive]:
                                                                allCookies,
                                                        })}
                                                    />
                                                </button>
                                            }
                                        >
                                            <div className={styles.accordionsContent}>
                                                {cookiesData?.analytics?.description && (
                                                    <p className="u-b3">
                                                        {cookiesData.analytics.description}
                                                    </p>
                                                )}
                                                <CookiesGDPRTable
                                                    table={cookiesData?.analytics?.table}
                                                />
                                            </div>
                                        </AccordionWithChildren>
                                    </div>
                                )}
                            </div>
                        )}
                        <div className={styles.btnSave}>
                            <Button
                                className="u-b1"
                                buttonHeight="small"
                                buttonWidth="xsmall"
                                onClick={() => {
                                    acceptCookies(allCookies);
                                }}
                            >
                                {cookiesData?.saveButton}
                            </Button>
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default CookiesGDPR;

interface TableProps extends React.HTMLAttributes<HTMLDivElement> {
    table: readonly Pick<
        FrontOption_Optionsacf_Cookies_Analytics_Table,
        'description' | 'cookieName' | 'duration'
    >[];
}

const CookiesGDPRTable = ({ table }: TableProps) =>
    table?.length > 0 ? (
        <div className={styles.table}>
            <table>
                <thead className="u-b1">
                    <tr>
                        <th>
                            <TranslationKey name="cookie" />
                        </th>
                        <th>
                            <TranslationKey name="duration" />
                        </th>
                        <th>
                            <TranslationKey name="description" />
                        </th>
                    </tr>
                </thead>
                <tbody className="u-b3">
                    {table.map((item, index) => (
                        <tr key={`necessary__${index}`}>
                            <td>{item?.cookieName}</td>
                            <td>{item?.duration}</td>
                            <td>{item?.description}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    ) : null;

interface AnalyticsButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    active?: boolean | null;
    onBtnClick?: (state: boolean) => void;
}
