import React from 'react';
import cx from 'classnames';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    type?: null | 'narrow' | 'wide' | 'full-width';
}

export const Container = React.forwardRef<HTMLDivElement, Props>(
    ({ children, type, ...rest }, ref) => (
        <div ref={ref} className={cx('o-container', type ? `o-container--${type}` : '')} {...rest}>
            {children}
        </div>
    )
);
