import React from 'react';

export const WhatsApp = ({ className = '', viewBox = '0 0 16 16' }) => (
    <svg
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        className={`u-icon u-icon--close ${className || ''}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <path
            d="M0.1,7.9c0-7,8.5-10.6,13.6-5.6l0,0C15.2,3.8,16,5.8,16,7.9c0,6-6.5,9.8-11.8,7L0,16l1.1-4.1
	C0.4,10.7,0.1,9.3,0.1,7.9z M4.7,13.6c4.4,2.6,10-0.6,10-5.7c0-1.8-0.7-3.4-1.9-4.7C11.5,2,9.8,1.3,8,1.3C2.8,1.3-0.3,7,2.4,11.4
	l0.2,0.2l-0.7,2.4l2.5-0.6L4.7,13.6z M11.7,9.6L11.7,9.6C11.7,9.5,11.7,9.6,11.7,9.6c0.2,0.1,0.3,0.1,0.4,0.2c0.1,0.1,0.1,0.5-0.1,1
	c-0.2,0.5-1,0.9-1.3,1c-0.3,0-0.8,0.1-1.3-0.1c-1.8-0.6-2.7-1-4.5-3.4C3.9,6.9,3.6,5.7,4.7,4.5c0.2-0.3,0.5-0.3,0.9-0.2c0,0,0,0,0,0
	c0.1,0,0.3,0,0.4,0.3C6.1,4.8,6.2,5,6.3,5.3C6.5,5.7,6.6,6,6.7,6.1c0,0.1,0.1,0.2,0,0.3C6.5,6.7,6.4,6.9,6.2,7
	C6.1,7.2,6,7.3,6.1,7.5c0.1,0.2,0.5,0.9,1.1,1.4c0.6,0.6,1.2,0.8,1.5,0.9c0.1,0,0.1,0,0.1,0.1C9,10,9.1,9.9,9.2,9.8
	C9.3,9.7,9.7,9.2,9.9,9c0.2-0.3,0.3-0.2,1.6,0.4C11.5,9.5,11.6,9.5,11.7,9.6z"
        />
    </svg>
);
