import React from 'react';
import cx from 'classnames';
import { getMappedCountry } from 'utils/getPMIcountryCodes';
import styles from './CountryFlag.module.scss';

interface Props {
    code: string;
    name: string;
    sport?: string;
    align?: 'default' | 'center';
    variation?: 'default' | 'small';
}

const DEFAULT_CDN_URL = '//cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/';

export const CountryFlag = React.forwardRef<HTMLUListElement, Props>(
    ({ code, name, sport, align = 'default', variation = 'default' }, ref) => {
        const mappedCountry = getMappedCountry(code);

        if (typeof code !== 'string' && mappedCountry) {
            return null;
        }

        const flagUrl = `${DEFAULT_CDN_URL}${mappedCountry?.toLowerCase()}.svg`;

        return (
            <ul ref={ref} className={cx('u-b2', styles.main, styles[align] || null)}>
                <li>
                    <span
                        title={name}
                        aria-label={name}
                        data-country={code}
                        className={cx(styles.flag, styles[variation] || null)}
                    >
                        <img src={flagUrl} alt={name} className={styles.flagImg} />
                    </span>
                </li>
                <li>
                    {sport ? (
                        <span>
                            {name}, {sport}
                        </span>
                    ) : (
                        <span>{name}</span>
                    )}
                </li>
            </ul>
        );
    }
);
