import React from 'react';

export const Play = ({ className = '', viewBox = '0 0 16 16' }) => (
    <svg
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        className={`u-icon u-icon--play ${className || ''}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <path d="M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z M6.3,10.7V5.3l4,2.7L6.3,10.7z" />
    </svg>
);
