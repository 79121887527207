import React from 'react';

export const Download = ({ className = '', viewBox = '0 0 21 23' }) => (
    <svg
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        className={`u-icon u-icon--download ${className || ''}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <path d="M0 15.498H1V21.998H20V15.498H21V22.998H0V15.498Z" />
        <path d="M10.853 20.355L15.853 15.355L15.1459 14.6479L11.0004 18.7933L11.0009 0.499991L10.0009 0.499966L10.0004 18.7953L5.85298 14.6479L5.14587 15.355L10.1459 20.355L10.4994 20.7085L10.853 20.355Z" />
    </svg>
);
