import React from 'react';

export const Cards = ({ className = '', viewBox = '0 0 40 34' }) => (
    <svg
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        className={`u-icon u-icon--cards ${className || ''}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <path d="M37.999 10.591H34.16l-1.077-8.13a2.016 2.016 0 00-2.25-1.717L1.738 4.6a2.001 2.001 0 00-1.327.772c-.324.42-.465.953-.392 1.479l2.455 18.514a2.001 2.001 0 002.243 1.724l1.927-.254v4.437c0 1.105.896 2 2.002 2h29.353a2.001 2.001 0 002.001-2v-18.68a2.001 2.001 0 00-2.001-2zm-5.184 0h-5.21l5.12-.678.09.678zM1.34 6.671a.654.654 0 01.132-.49.667.667 0 01.444-.26l29.09-3.854a.676.676 0 01.754.573l.263 1.987-30.42 4.034-.264-1.99zM6.644 25.49l-2.098.277a.667.667 0 01-.75-.574L2.308 13.947l4.336-.573V25.49zm.088-13.473l-4.603.607-.35-2.64L32.2 5.95l.2 1.529.149 1.11-15.096 2.002H8.646c-.883.002-1.66.58-1.914 1.426zm31.934 19.255a.667.667 0 01-.667.667H8.646a.667.667 0 01-.667-.667v-18.66c0-.006.003-.013.003-.02 0-.368.299-.667.667-.667H38c.368 0 .667.299.667.667v18.68z" />
        <path d="M29.29 20.613c.267-.198.504-.435.702-.703a3.33 3.33 0 102.669-5.316 3.324 3.324 0 00-2.669 1.355 3.336 3.336 0 10-.703 4.664zm3.37-4.685a2.001 2.001 0 110 4.003 2.001 2.001 0 010-4.003zm-5.336 0a2.001 2.001 0 110 4.003 2.001 2.001 0 010-4.003zM11.983 15.26h-1.335v1.335h1.335v-1.334zM21.322 15.26h-8.006v1.335h8.006v-1.334zM21.322 17.929H10.648v1.334h10.674v-1.334zM35.332 23.933H11.316a.667.667 0 00-.668.667v4.002c0 .369.3.668.668.668h24.016a.667.667 0 00.667-.668V24.6a.667.667 0 00-.667-.667zm-.668 4.002H11.983v-2.668h22.681v2.668z" />
    </svg>
);
